import React from 'react'
import { ReactComponent as NarcoticOd } from './resources/5.1.1.svg'
import { ReactComponent as AlteredLoc } from './resources/5.2.1.svg'
import { ReactComponent as Diabetic } from './resources/5.4.1.svg'
import { ReactComponent as Seizure } from './resources/5.5.1.svg'
import { ReactComponent as Poisoning } from './resources/5.6.1.svg'
import { ReactComponent as Depression } from './resources/5.7.1.svg'
import { ReactComponent as Gynecological } from './resources/5.8.1.svg'
import { ReactComponent as Abdominal } from './resources/5.9.1.svg'
import { ReactComponent as IGel } from './resources/5.10.1.svg'

import { Topic } from '.'
import { generateTable, generateRow } from '../util'

export const medicalProtocols: Topic[] = [
    {
        id: 'i-gel',
        sectionId: 'protocols',
        charts: [],
        title: 'I-GEL',
        lastUpdated: '6 Jan 2022',
        body: '',
        markup: <>
            <h2><strong>Introduction</strong></h2>
            <p>The i-Gel supraglottic airway device is a tool used to provide a higher degree of airway protection that can be obtained through the use of a pharyngeal airway.  It transfers the working interface between the bag-valve mask from the face to the laryngeal inlet.  Rescue Specialists may use supraglottic devices in the setting of cardiac arrest or in patients who are obtunded and breathing spontaneously.</p>
            <ul>
                <li>When preparing for SGA insertion, a pre-connected viral filter must be used.</li>
                <li>Reversion to bag-valve mask ventilation with a tight seal and viral filter should be used if SGA placement fails.</li>
            </ul>
            <h2><strong>Indications</strong></h2>
            <p>Patients who are unable to protect their airways due to a decreased level of consciousness </p>
            <h2><strong>Contraindications</strong></h2>
            <ul>
                <li>Inability to place device due to difficulties with mouth opening</li>
                <li>Known or suspected pathological or foreign-body airway obstruction, including epiglottitis</li>
                <li>Trauma to the trachea, neck, or oropharynx</li>
                <li>Caustic ingestion</li>
                <li>Active vomiting</li>
            </ul>
            
            <p>Select an appropriately-sized supraglottic airway and remove it from its packaging and cradle. EGD sizing is based on patient weight.</p>

            <IGel />

            <h2><strong>If it becomes necessary to remove a supraglottic device:</strong></h2>
            <ol>
                <li>Where possible, raise the patient to a semi-recumbent position (30°).</li>
                <li>Prepare suction, bag-valve mask, and oxygen delivery devices.</li>
                <li>Cut or remove ties or tube holders.</li>
                <li>Ask the patient to take a deep breath, then blow out firmly.  While the patient is blowing out, pull the airway smoothly out of the mouth. </li>
                <li>Suction the oropharynx as needed.</li>
                <li>Monitor oxygen saturation.</li>
                <li>Support respirations as needed.</li>
            </ol>

            <h2>Notes</h2>
            <p>Airway obstructions are an absolute contraindication to the use of a supraglottic airway.  Rescue Specialists must, therefore, confirm they are able to ventilate the patient with a bag-valve mask prior to placing a supraglottic airway.</p>
            <p>The supraglottic airway is a tool to solve problems relating to oxygenation and ventilation.  Rescue Specialists should apply a staged approach to airway problem solving prior to using a supraglottic airway.</p>

        </>
    },
    {
        id: 'narcotic-overdose',
        sectionId: 'protocols',
        charts: [],
        title: 'Narcotic Overdose',
        lastUpdated: '6 Jan 2022',
        body: '',
        markup:<>
            <NarcoticOd />
            <h2><strong>NOTES</strong></h2>
            <ol>
                <li>Opioid overdoses require optimal ventilation and oxygenation prior to consideration of Naloxone.</li>
                <li>Naloxone should only be given if the LOC is altered not responding to stimulation AND either respiratory rate is depressed (&lt;10/min) or difficulty in maintaining airway.</li>
                <li>Beware of complications of Naloxone administration including: Combativeness, violence especially if the patient has been taking other drug or acute respiratory distress (rare)</li>
                <li>Deterioration after 23-30 minutes as Naloxone effect diminishes and the opiate re-establishes its effect.</li>
                <li>If patient improves but later trends downward to meet indications once again - Re-initiate the protocol.</li>
                <li>If after 2 consecutive doses the patient still meets the indications – indications – call EPOS for further orders and advice.</li>
            </ol>
            <h2><strong>Indications</strong></h2>
            <p>Decreased LOC in a patient with a history that suggests narcotic overdose presenting as follows:</p>
            <ul>
                <li>Altered LOC not responding to stimulation and,</li>
                <li>Difficulty maintaining patient’s airway AND /OR</li>
                <li>Respiratory rate &lt;10 per min</li>
            </ul>
            <h2><strong>Contraindications</strong></h2>
            <p>Allergy or known hypersensitivity to naloxone.</p>
            <h2><strong>Critical History</strong></h2>
            <ul>
                <li>What drug did the patient take?</li>
                <li>Did the patient take other drugs?</li>
                <li>How much did they take and by what route?</li>
                <li>When did they take it?</li>
                <li>Is this the dose the patient normally takes?</li>
                <li>Are there any previous reactions to the drug?</li>
                <li>What has been done for the patient prior to RS arrival?</li>
                <li>Did anyone else take the same drugs? What is there condition? – consider a possible similar effect with others.</li>
            </ul>
            <h2><strong>Key Features</strong></h2>
            <ul>
                <li>Decreased LOC – may not respond to stimuli. Sternal rub or trap squeeze is an effective assessment of pain stimulus.</li>
                <li>Decreased or absent respirations.</li>
                <li>Hypotension</li>
                <li>Pale, cool, clammy – may have signs of cyanosis.</li>
                <li>Decreased muscle tone.</li>
                <li>Pinpoint pupils.</li>
                <li>May have track marks</li>
            </ul>
            <h2><strong>Physical Assessment</strong></h2>
            <ul>
                <li>RSE - Watch for sharps.</li>
                <li>Primary – ensure effective airway management and assist respirations as necessary with high flow O2.</li>
                <li>Proper assessment for key features of Narcotic OD. There are many causes of decreased LOC.</li>
                <li>Look for pin point pupils, hypoventilation and track marks</li>
                <li>Initial Naloxone dose is administered on scene if available.</li>
            </ul>
            <h2><strong>Considerations</strong></h2>
            <p>Decreased LOC in narcotic overdose has likely resulted from hypoxia due to decreased respiratory rate and inability of the patient to maintain their own airway as well as possibly hypotension.</p>
            <p>All patients that have been administered Naloxone should be transported to the hospital. <strong>If patient refuses </strong>try to explain the importance of follow up care (see notes on flowchart page) and if patient still refuses then have them sign your Patient Care Record stating that they have refused treatment and transport.</p>
            <p>Narcotics (opioids) are a Central Nervous System depressant. Naloxone is a synthetic opioid antagonist and reverses the effects of the narcotic. However, it has a shorter active life in the body then most opiates and as a result the patient may overdose again once the Naloxone has ‘worn off’.</p>
            <p>Naloxone disrupts the contact point between the opioid and the opioid receptor, in effect taking the place of the opioid and blocking off the opioid receptor. Naloxone has an affinity for this receptor over 100 times more than that of opioid molecules. This is why Naloxone is so effective.</p>
            <p>Naloxone has no pharmacological activity on its own and will generally produce no side effects if given to a patient who is not having a Narcotic overdose. However, if a patient was on a narcotic medication for pain, as an example, it would remove the analgesic effects of that medication.</p>
            <h2><strong>Common Narcotics</strong></h2>
            <ul>
                <li>Morphine</li>
                <li>Demerol</li>
                <li>Fentanyl</li>
                <li>Heroin</li>
                <li>Methadone</li>
                <li>Opium</li>
                <li>Oxycodone</li>
                <li>Hydromorphone</li>
                <li>Tylenol 3 / Emtec / Codeine</li>
                <li>Tramadol</li>
            </ul>
        </>,
    },
    {
        id: 'altered-loc',
        sectionId: 'protocols',
        charts: [],
        title: 'Altered LOC (Not Yet Diagnosed – NYD)',
        lastUpdated: '6 Jan 2022',
        body: '',
        markup: <>
            <AlteredLoc />
            <h2><strong>Indications</strong></h2>
            <p><strong>Decreased LOC, not yet diagnosed.</strong></p>
            <h2><strong>Contraindications</strong></h2>
            <p>Do not administer glucose to a decreased LOC patient if they are positioned on their back.</p>
            <p>Allergy or known hypersensitivity to naloxone.</p>
            <p>See applicable protocol – Narcotic Overdose or Diabetic.</p>
            <h2><strong>Critical History</strong></h2>
            <ul>
                <li>Is the environment safe to enter (toxic, CO etc)?</li>
                <li>Does anyone know or suspect a reason for the persons current presentation (decreased LOC)? If provided with answers follow logical history taking to determine if it is the cause.</li>
                <li>Has the patient taken any drugs or medication? If so :</li>
            </ul>
            <p>What drug or medication (s) did they take?</p>
            <p>How much did they take and by what route?</p>
            <p>When did they take it?</p>
            <p>If this the dose the patient normally takes?</p>
            <p>Are there any previous reactions to the drug?</p>
            <p>Is the drug an opioid? If so refer to Narcotic OD critical history and key features.</p>
            <p>What has been done for the patient prior to RS arrival?</p>
            <ul>
                <li>Questions using <strong>AEIOU TIPS (See Assessments Tab)</strong></li>
            </ul>
            <h2><strong>Key Features</strong></h2>
            <ul>
                <li>Key features will depend on the cause of the decreased LOC. Once you determine cause – see relevant protocol and its key features.</li>
                <li>Pin point pupils may indicate Narcotic Overdose or poisoning</li>
                <li>Dilated pupils may indicate hypoglycemia, hypoxia or shock</li>
                <li>Some street drugs like cocaine and ecstasy cause dilated pupils</li>
                <li>Slow heart rates may indicate head injury, hypothermia, ACS or poisonings.</li>
                <li>Slow heart rates may also be caused by overdosing on prescription medications for heart rate and blood pressure control</li>
            </ul>
            <h2><strong>Physical Assessment</strong></h2>
            <ul>
                <li>Primary – ensure effective <strong>airway management </strong>and assist respirations as necessary with high flow O2.</li>
                <li>Ensure completion of primary – <strong>do not get side-tracked with history taking initially. </strong>Treat patient with necessary critical interventions.</li>
                <li>Proper assessment and history for relevant differential diagnosis or if still unknown cause – <strong>treat the patients’ current presentation</strong>.</li>
                <li><strong>Transport should not be </strong>delayed for a critical patient in order to find a possible diagnosis. Do an appropriate amount of history taking based on critical history and then transport.</li>
            </ul>
            <h2><strong>Considerations</strong></h2>
            <p>There are many causes for decreased LOC and only a few are treatable in the field. Determining if it is a treatable cause is done through effective and efficient assessment and history taking.</p>
            <p>The first job of the RS is to complete a <strong>proper primary assessment </strong>with critical interventions and make a transport decision based on the patient’s condition.</p>
            <p>Then the RS can do <strong>an effective history </strong>by using SAMPLE, OPQRST, critical history and key features. DO NOT spend a large amount of time on scene attempting</p>
            <p>to determine a cause – if it is not readily apparent through your history taking then consider <strong>transport.</strong></p>
            <p>Treating early with naloxone will improve respiratory rate and volume.</p>
            <p>If the patient has adequate respirations, treat later with naloxone as a means to rule out a lower dose of narcotic ingestion as the reason for the unconscious state.</p>
            <p><strong>Caution: It is always good to have a baseline set of vitals.</strong></p>
        </>,
    },
    {
        id: 'other-drugs',
        sectionId: 'protocols',
        charts: [],
        title: 'Other Drugs',
        lastUpdated: '6 Jan 2022',
        body: '',
        markup: <>
            <h2><strong>Indications</strong></h2>
            <p>Drug use</p>
            <h2><strong>Critical History</strong></h2>
            <ul>
                <li>Does anyone know or suspect a reason for the persons current presentation? If provided with answers follow logical history taking to determine if it is the cause.</li>
                <li>Has the patient taken any drugs or medication? If so :</li>
            </ul>
            <p>
                <br/>
            </p>
            <p>What drug or medication (s) did they take?</p>
            <p>How much did they take and by what route?</p>
            <p>When did they take it?</p>
            <p>If this the dose the patient normally takes?</p>
            <p>Are there any previous reactions to the drug?</p>
            <p>Is the drug an opioid? If so refer to Narcotic OD critical history and key features.</p>
            <p>
                <br/>
            </p>
            <ul>
                <li>What has been done for the patient prior to RS arrival?</li>
            </ul>
            <p>Questions using AEIOU TIPS.</p>
            <h2><strong>Key Features</strong></h2>
            <ul>
                <li>Key features will depend on the drug in question.</li>
            </ul>
            {generateTable({
                title: 'Other Drug Key Features',
                header: generateRow('Drugs', 'Signs and Symptoms', 'Routes', 'Management'),
                rows: [
                    generateRow('Barbituates, Thiopental, Phenobarbital, Primidone', 'Lethargy, Emotional lability, Incoordintion, Slurred speech, Nystagmus, Coma Hypotention, Respiratory depression', 'Oral, IV', 'ABCs, Respiratory support, O2, Maintain warmth'),
                    generateRow('Cocaine, Crack, Rock', 'Euphoria, Hyperactivity, Dilated pupils, Psychosis, Twitching, Anxiety, Hypertension, Tachycardia, Dysrhythmias, Seizures, Chest pain', 'Snorting, Injection, Smoking, (freebasing)', 'Protect self and crew from harm as patient may be violent. Reassure patient, provide calm environment. Manage ABCs'),
                    generateRow('Amphetamines, Benzedrine, Dexedrine, Ritalin (speed)', 'Exhilaration, Hyperactivity, Dilated pupils, Hypertension, Psychosis, Tremors, Seizures', 'Oral, Injection', 'Reassure patient, provide calm environment'),
                    generateRow('Hallucinogens, LSD, STP, Mescaline, Psilocybin, PCP', 'Psychosis, Nausea, Dilated pupils, Rambling speech, Headache, Dizziness, Suggestibility, Distortion of sensory perceptions and hallucinations', 'Oral, smoking', 'Reassure patient, provide calm environment'),
                    generateRow('Sedatives Diazepam, Midazolam, Clonazepam, Valium, Xanax, Phenobarbital', '', 'Oral or IV injection', 'Manage airway and respitory support'),
                ],
            })}
            <h2><strong>Physical Assessment</strong></h2>
            <ul>
                <li>Primary – ensure effective airway management and assist respirations as necessary with high flow O2.</li>
                <li>Ensure completion of primary – do not get side-tracked with history taking initially. Treat patient with necessary critical interventions.</li>
                <li>Proper assessment of history for relevant differential diagnosis and key features. If cause still unknown cause – treat the patients’ current presentation.</li>
                <li>Transport should not be delayed for a critical patient in order to find a possible diagnosis. Do an appropriate amount of history taking based on critical history and then transport.</li>
            </ul>
            <h2><strong>Considerations</strong></h2>
            <p>There are many causes for decreased LOC and only a few are treatable in the field. Determining if it is a treatable cause is done through effective and efficient assessment and history taking.</p>
            <p>The first job of the RS is to complete a <strong>proper primary assessment survey </strong>with critical interventions and make a transport decision based on the patient’s condition.</p>
            <p>Then the RS can do <strong>an effective history assessment </strong>by using SAMPLE, OPQRST, critical history and key features. DO NOT spend a large amount of time on scene attempting to determine a cause – if it is not readily apparent through your history taking then <strong>transport.</strong></p>
        </>
    },
    {
        id: 'diabetic',
        sectionId: 'protocols',
        charts: [],
        title: 'Diabetic',
        lastUpdated: '6 Jan 2022',
        body: '',
        markup: <>
        <Diabetic />
        <p><strong>NOTES</strong>:</p>
        <p>B G&gt;11mmol/L is considered hyperglycemic. TRANSPORT to higher level of care. Reassess BG 1/hr or if there is a change in behaviour or patient condition.</p>
        <p>B G 4.0 - 11 mmol/L</p>
        <ul>
            <li>confirm history and patient presentation.</li>
            <li>consider other causes of decreases LOC (AEIOU TIPS).</li>
            <li>initiate transport if not sure of cause. Then consider use of Narcotic OD protocol or contacting EPOS for further instructions.</li>
        </ul>
        <p>Oral glucose should be given to conscious patient to self-administer.</p>
        <p>If decreased LOC – ensure patient is ¾ to drain airway, administer small amounts via tongue depressor buccally (between cheek and gum) on the lower cheek.</p>
        <p>Reassess BG every 5-10 mins, once BG is &gt; 4mmol/L monitor BG 1/hr or if patient behaviour or conditions changes.</p>
        <p>DO NOT ASSIST WITH PATIENTS INSULIN OR OTHER DIABETIC MEDICATION.</p>
        <h2><strong>Indications</strong></h2>
        <p>Known diabetic patient whose history and presentation suggests a diabetic emergency. (Hypoglycemia or hyperglycemia)</p>
        <h2><strong>Contraindications</strong></h2>
        <p>Do not administer glucose to a decreased LOC patient if they are positioned on their back.</p>
        <h2><strong>Critical History</strong></h2>
        <ul>
            <li>SAMPLE and OPQRST</li>
            <li>Have you taken your usual dose of your medication today?</li>
            <li>Have you had any recent changes to your medication(s)?</li>
            <li>Have you eaten normally?</li>
            <li>Have you had any unusual activity, stress or illness recently?</li>
            <li>When did this start?</li>
            <li>Did it come on gradually or suddenly?</li>
        </ul>

        {generateTable({
            title: 'Hypoglycemia and Hyperglycemia Indicators',
            header: generateRow('HYPOGLYCEMIA (BG < 4.0MMOL/L)', 'HYPERGLYCEMIA (BG>11MMOL/L)'),
            rows: [
                generateRow('AKA INSULIN SHOCK, VERY LOW BLOOD SUGAR, MOST COMMON AND CRITICAL DIABETIC EMERGENCY, BRAIN DAMAGE CAN OCCUR QUICKLY', 'AKA DIABETIC COMA OR KETOACIDOSIS, HIGH BLOOD SUGAR. BODY IS UNABLE TO ACCESS GLUCOSE SO IT BURNS FAT – LEADS TO DEHYDRATION AND ACIDOSIS'),
                generateRow('Rapid onset of altered mental status to unconscious.', 'Gradual onset of altered mental status or feeling unwell. Late stages may be unconscious'),
                generateRow('Recent history of decreased food intake, increased exercise, illness, excessive insulin dosage and/or recent medication changes.', 'History of non-compliance with medication or may be initial presentation of diabetes.'),
                generateRow('Agitated, combative, may appear intoxicated', 'Restless, could progress to unconscious.'),
                generateRow('Pale, cool, moist skin', 'Flushed/dry skin or pale/diaphoretic'),
                generateRow('Increased heart rate', 'Increased heart rate'),
                generateRow('Normal respirations', 'Deep rapid respirations Fruity odor on breath'),
                generateRow('Headache', 'Nausea / vomiting'),
                generateRow('Seizures (late stages)', 'Thirsty'),
                generateRow('', 'Hunger'),
                generateRow('', 'Frequent urination'),
            ],
        })}

        <h2><strong>Physical Assessment</strong></h2>
        <ul>
            <li>Primary – maintain airway.</li>
            <li>Look for a medic alert and diabetic medications.</li>
            <li>Generally considered an unstable patient – arrange for transport</li>
            <li>Recovery position if patients LOC is decreased.</li>
            <li>If you cannot determine if the problem is hypoglycemia or hyperglycemia administer sugar to the patient.</li>
            <li>All patients should be transported.</li>
            <li><strong>If patient refuses care after treatment </strong>and their LOC has returned to GCS 15, ensure refusal is signed and patient is left in the care of a capable adult.</li>
        </ul>
        <p>Important to determine or stress that there is likely and underlying cause that should be managed to prevent reoccurrence.</p>
        <p><strong>Assisting with Oral Glucose</strong></p>
        <ul>
            <li>For <strong>responsive patient (alert enough to swallow) with hypoglycemia </strong>assist the patient in taking sugar in some form (e.g. glucogel, honey, sweet juice, etc.)</li>
        </ul>
        <p>The patient should improve within several 20 minutes of sugar.</p>
        <p>This can be followed with more complex carbohydrates (e.g. whole milk, a balanced meal) for longer-term benefit. Continue transport to medical aid.</p>
        <ul>
            <li><strong>Unresponsive with suspected hypoglycemia </strong>begin transport. Place glucogel (carefully, in small amounts) between the cheek and gum (use tongue depressor). Aspiration risk, protect airway with position (3/4 prone) and suction.</li>
        </ul>
        <h2><strong>Considerations</strong></h2>
        <p>Diabetes Mellitus – disease marked by inadequate insulin activity in the body.</p>
        <ul>
            <li><strong>TYPE 1 (Insulin Dependent) </strong>– More serious but less common. Typical juvenile onset as pancreas produces little or no insulin. Managed by regular insulin injection.</li>
            <li><strong>TYPE 2 (Non-insulin Dependent) </strong>– More common type - typically adult onset. Inadequate insulin production or decreased response to insulin. Managed by diet and oral medications that boost insulin. Some cases require supplemental insulin.</li>
        </ul>
        <p><strong>Glucometer </strong>– normal blood glucose levels measured between meals would be between 5 – 7 mmol/ml; a blood glucose reading below 4.0 indicates hypoglycemia and requires assistance with sugar. Giving oral glucose to the diabetic with altered mental status does not depend on a measured blood sugar level.</p>
        </>
    },
    {
        id: 'seizure',
        sectionId: 'protocols',
        charts: [],
        title: 'Seizure',
        lastUpdated: '6 Jan 2022',
        body: '',
        markup:<>
            <Seizure />
            <h2><strong>Critical History</strong></h2>
            <ul>
                <li>What was the seizure focal or grand mal?</li>
                <li>How long did the seizure last? Was there more than one in a row? Status Epilepticus – 2 or more generalized seizures with no return of consciousness in between.</li>
                <li>Were there any symptoms preceding the seizure?</li>
                <li>Is the patient diagnosed with a seizure disorder? If so - Is this seizure activity normal for this patient? Is the patient on any medication? Is the patient compliant with that medication? Any recent changes to the medication? Has the patient had any recent illness?</li>
                <li>If the patient is not diagnosed with a seizure disorder, attempt to determine cause (see below).</li>
            </ul>
            <p><strong>Common Causes</strong></p>
            <ul>
                <li>Cardiac arrest</li>
                <li>Hypoxia</li>
                <li>Hypoglycemia</li>
                <li>Fever (common in infants and children)</li>
                <li>Infection</li>
                <li>Head trauma</li>
                <li>Poisoning</li>
                <li>Drug or alcohol abuse or withdrawal</li>
                <li>Non-compliant with medication</li>
                <li>Stroke</li>
                <li>High blood pressure (hypertensive event)</li>
                <li>Idiopathic (unknown cause)</li>
            </ul>
            <h2><strong>Key Features</strong></h2>
            <p><strong>Phases of activity – Aura, tonic, clonic and postictal.</strong></p>
            <ol>
                <li>Aura – a specific, characteristic sensation preceding an episode and warning of its onset. (a smell, taste, visual disturbance, etc.)</li>
                <li>Tonic – ridged phase.</li>
                <li>Clonic – spasm phase. Violent jerking of the body musculature.</li>
                <li>before recovery.</li>
                <li>The entire tonic/clonic phase of the seizure would typically last 1-2 minutes.</li>
                <li>Postictal – the longest portion of the seizure. The stuporous state following the actual seizure, in which the patient can appear lethargic,</li>
                <li>may yawn frequently, is confused or disoriented, and may complain of headache or sore muscles.</li>
            </ol>
            <p>The length of the postictal phase is often indicative of the length of the tonic/clonic phase.</p>
            <p>Other signs and symptoms may include</p>
            <ul>
                <li>Rotation of the eyes, transient apnea followed by gasping respiration</li>
                <li>Flushing skin followed by cyanosis</li>
                <li>Increased salivation</li>
                <li>Clenching of the jaw with possible trauma to the tongue</li>
                <li>Possible incontinence</li>
            </ul>
            <h2><strong>Physical Assessment</strong></h2>
            <ul>
                <li><strong>Primary concern is airway and breathing.</strong></li>
                <li><strong>Protect from further injury </strong>by moving objects away rather than restraining movements. Protect the head without resistance.</li>
                <li>Protect <strong>airway</strong>. Assist breathing as required, use suction and ¾ prone positioning.</li>
                <li><strong>Oxygen </strong>should only be administered if the SPO2 is &lt;95% and/ or the patient presents with obvious signs of shock (pale, cool, clammy skin) cyanosis or SOB. Assist ventilation as required</li>
                <li><strong>Do not insert anything by mouth (except an POA) </strong>or between teeth of the seizing patient.</li>
                <li><strong>Consider potential trauma </strong>as a result of a fall or the seizure itself.</li>
                <li>Complete patient assessment. If possible, determine <strong>cause of the seizure</strong>.</li>
            </ul>
            <h2><strong>Considerations</strong></h2>
            <p><strong>Seizures – </strong>A disturbance caused by massive electrical discharge in a group of nerve cells in the brain.</p>
            <p>Most seizures are by their nature self-limiting and not life threatening, however if seizure activity is prolonged or a series of seizures occur in rapid succession then a condition known as <strong>STATUS EPILEPTICUS </strong>exists. This is a dire emergency, as the brain will be deprived of oxygen. Rapid transport to medical aid is required.</p>
            <p><strong>Focal Seizures</strong></p>
            <ul>
                <li>Consists of rhythmic twitching of muscle groups, particularly in the extremities and face.</li>
            </ul>
            <p><strong>Absence Seizures</strong></p>
            <ul>
                <li>A type of generalized seizure with sudden onset, characterized by a brief loss of awareness and rapid recovery.</li>
            </ul>
            <p><strong>General Motor (Grand Mal) Seizures</strong></p>
            <ul>
                <li>Generalized seizure characterized by rapid loss of consciousness and motor coordination, muscle spasms, and jerking motions.</li>
            </ul>
        </>,

    },
    {
        id: 'poisoning',
        sectionId: 'protocols',
        charts: [],
        title: 'Poisoning',
        lastUpdated: '6 Jan 2022',
        body: '',
        markup: <>
            <h2><strong>Indications</strong></h2>
            <p>Confirmed ingestion, inhalation, injection or exposure to topical poison or chemical.</p>
            <p>Patient may or may not be symptomatic at time of assessment.</p>
            <h2><strong>Critical History</strong></h2>
            <ul>
                <li>Was the poison ingested, inhaled, injected or absorbed through the skin?</li>
                <li>What did the patient take or come in contact with? If ingested, was it caustic or a petroleum distillate?</li>
                <li>How much was taken and when? Or what was the amount or time frame of the exposure?</li>
                <li>Was there suicidal intent?</li>
                <li>Did the patient mix substances?</li>
                <li>When did the symptoms begin? Did the patient take anything after?</li>
                <li>Has the patient vomited – what was the vomitus like?</li>
                <li>Is there any headache, dizziness, hallucination or visual disturbance?</li>
            </ul>
            <h2><strong>Key Features</strong></h2>
            <p><strong>CO Poisoning</strong></p>
            <ul>
                <li>Headache</li>
                <li>Nausea / vomiting</li>
                <li>Confusion or other altered mental status</li>
                <li>Increased respiratory rate</li>
                <li>SPO2 will read 100% or very high – do not use.</li>
                <li>Flushed skin is a late sign</li>
            </ul>
            <p><strong>Caustic Ingestion</strong></p>
            <ul>
                <li>Burning sensations</li>
                <li>Nausea, vomiting (may have blood in vomit)</li>
                <li>Drooling, pain on swallowing, inability to swallow</li>
                <li>Shortness of breath</li>
            </ul>
            <p><strong>Hydrocarbon Ingestion</strong></p>
            <ul>
                <li>Persistent coughing after swallowing or inhaling</li>
                <li>Increasing respiratory distress</li>
                <li>Possible cyanosis</li>
                <li>Drowsiness, poor coordination, stupor, seizures</li>
            </ul>
            <p><strong>Non Caustic Ingestion</strong></p>
            <ul>
                <li>Substance dependent</li>
            </ul>
            <p><strong>Jellyfish Sting</strong></p>
            <ul>
                <li>Burning sensation</li>
                <li>Pain at the site</li>
                <li>Redness or rash of the skin at the site</li>
            </ul>
            <p><strong>Absorbed / Topical Poison</strong></p>
            <ul>
                <li>Substance dependent</li>
                <li>May have only dermatological effects or may have multi system effects</li>
            </ul>
            <h2><strong>Physical Assessment</strong></h2>
            <ul>
                <li>Assess scene and ensure safety of rescuers. Consider history</li>
                <li>Primary assessment with particular attention to airway and breathing. Position for drainage.</li>
                <li>Never give anything by mouth to the patient with a reduced LOC</li>
                <li>Conduct secondary assessment and seek medical advice during transport</li>
                <li>Refer to WHIMIS / MSDS and Poison Control Center.</li>
            </ul>
            <h2><strong>Considerations</strong></h2>
            <p><strong>If advised to use Activated Charcoal by Poison Control</strong></p>
            <p><strong>Activated Charcoal </strong>(aqueous charcodote) – 250 ml bottle contains 50 g activated charcoal oral suspension in purified water – prevents absorption of some poison types; not effective for corrosives or petroleum products; shake well, use as directed by poison control – usual dose (child to adult) is 65-250 ml (13-50 g of activated charcoal).</p>
        </>

    },
    {
        id: 'poisoning-psp',
        sectionId: 'protocols',
        charts: [],
        title: 'Poisoning and PSP',
        lastUpdated: '6 Jan 2022',
        body: '',
        markup:<>
            <Poisoning />
            <h2><strong>Indications</strong></h2>
            <p><strong>Ingestion of bivalves harvested after a “Red Tide” algae bloom.</strong></p>
            <h2><strong>Contraindications</strong></h2>
            <p><strong>Activated Charcoal</strong></p>
            <ul>
                <li> Not to be administered if the patient cannot swallow or has a decreased LOC.</li>
                <li>Not to be administered if patient is vomiting.</li>
            </ul>
            <h2><strong>Critical History</strong></h2>
            <ul>
                <li>What was ingested and when?</li>
                <li>How much was consumed?</li>
                <li>Did anyone else consume the same?</li>
                <li>What was the location of the harvest?</li>
                <li>Take a sample of the uneaten food if possible. (may be frozen)</li>
                <li>Ensure no other persons ingest the harvested food?</li>
            </ul>
            <h2><strong>Key Features</strong></h2>
            <p><strong>Usual onset of 10-30 minutes, may be delayed up to 10 hours.</strong></p>
            <ul>
                <li>Initially numbness of lips, tongue, face and extremities.</li>
                <li>Headache, dizziness, drowsiness, muscle weakness leading to paralysis. All muscle with probable exception of cardiac and vascular is paralyzed. Death is due to respiratory paralysis.</li>
                <li>Dysphagia, (a choking sensation) and difficulty in speaking may occur.</li>
                <li>Feeling of lightness. Possible visual impairment or temporary blindness.</li>
                <li>Nausea, vomiting, and abdominal pain. Low blood pressure, rapid pulse.</li>
            </ul>
            <h2><strong>Patient Assessment</strong></h2>
            <ul>
                <li><strong>As is in flow chart.</strong></li>
                <li><strong>Ensure use of BVM for positive pressure </strong>ventilation or mouth to mask if needed. <strong>No lip to lip contact as rescuer could be affected.</strong></li>
                <li><strong>AR is more likely but if the condition has progressed CPR may be indicated.</strong></li>
            </ul>
            <h2><strong>Considerations</strong></h2>
            <p><strong>There is no specific antidote.</strong></p>
            <p><strong>Paralytic Shellfish Poisoning (PSP) – </strong>Caused by the ingestion of bivalves harvested after a “Red Tide” algae bloom - This algae produces an extremely powerful toxin (saxitoxin) which paralyses by blocking neuromuscular transmission.</p>
        </>,
    },
    {
        id: 'depression-agitation',
        sectionId: 'protocols',
        charts: [
        ],
        title: 'Depression, Agitation, and Suicidal States',
        lastUpdated: '6 Jan 2022',
        body: '',
        markup:<>
        <Depression />
        <h2><strong>Indications</strong></h2>
        <p><strong>Behaviour that presents a danger to patient or others or that delays or prevents appropriate care, is disruptive and may precipitate a psychological emergency.</strong></p>
        <h2><strong>Critical History</strong></h2>
        <ul>
            <li>If the nature of the call is known, confirm that it is deemed safe to enter.</li>
            <li><strong>Is the environment dangerous </strong>to you and/or others?</li>
            <li>Does the patient seem agitated, elated, depressed, or restless?</li>
            <li>Aggressive or violent behaviour? Do they talk loudly or in sarcastic manner?</li>
            <li>Do they use vulgar language? Easily provoked to anger? Limited attention span?</li>
            <li>Does the patient seem to be out of control, afraid or panicky?</li>
            <li>Evidence of alcohol or drug use? Criminal activity? <strong>Do they have a weapon</strong>?</li>
            <li>Is there any medical or trauma related reason for the patients behaviour.</li>
            <li>Does the patient have a diagnosed mental health condition?</li>
            <li>If the patient is medicated for a mental health issue, are they compliant with their meds?</li>
        </ul>
        <h2><strong>Key Features</strong></h2>
        <ul>
            <li>May vary depending on condition. Patient may also have multiple mental health issues.</li>
        </ul>
        <h2><strong>Patient Assessment</strong></h2>
        <p><strong>Considerations during your assessment.</strong></p>
        <ul>
            <li>General appearance</li>
            <li>Behavioral observations</li>
            <li>Orientation – Person, place and time</li>
            <li>Memory – Intact for recent and long term events</li>
            <li>Sensorium – Patient focused? Able to pay attention?</li>
            <li>Perceptual processes – Hallucinations, delusions or phobias</li>
            <li>Mood and affect – Is it appropriate? What is the prevailing emotion?</li>
            <li>Intelligence – Speech ok? Able to formulate an idea?</li>
            <li>Thought processes – Logical and coherent?</li>
            <li>Insight – Does patient have insight into own problems or deny or blame?</li>
            <li>Judgement – Reasonable?</li>
            <li>Psychomotor – Unusual posture or unusual movements?</li>
        </ul>
        </>
    },
    {
        id: 'gynecological-obstetric',
        sectionId: 'protocols',
        charts: [],
        title: 'Gynecological / Obstetric Emergencies',
        lastUpdated: '6 Jan 2022',
        body: '',
        markup: <>
            <Gynecological />
            <h2><strong>Critical History</strong></h2>
            <ul>
                <li>Has the patient experienced a similar problem before?</li>
                <li>Is the patient pregnant? If so, how far along?</li>
                <li>Have there been any complications?</li>
                <li>Are there any signs of abdominal trauma? or sexual abuse?</li>
            </ul>
            <p><strong>IN CASE OF PREGNANCY</strong></p>
            <ul>
                <li>How many pregnancies have you had including this one? Any trouble with previous pregnancies/deliveries?</li>
                <li>Has the mother had any trouble with this pregnancy?</li>
                <li>Have the membranes ruptured? Is amniotic fluid stained or foul smelling?</li>
                <li>If there are contractions - when did they start? What is the frequency,</li>
            </ul>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;severity, and duration?</p>
            <ul>
                <li>Has the patient had prenatal care? If so, by whom? Do they have a birth plan?</li>
                <li>Does the patient have any underlying medical problems?</li>
                <li>Is the patient taking any medications?</li>
                <li>Does the patient have pain other than the contractions?</li>
            </ul>
            <h2><strong>Key Features</strong></h2>
            <p><strong>Signs of Pre-Delivery Emergency</strong></p>
            <ul>
                <li>Abdominal pain</li>
                <li>Nausea/vomiting&nbsp;</li>
                <li>seizures;</li>
                <li>abdominal trauma;</li>
                <li>vaginal bleeding (pre-birth) or passage of tissue;</li>
                <li>signs of shock,</li>
                <li>weakness,</li>
                <li>dizziness or altered mental status;</li>
                <li>excessive swelling of face and/or extremities;</li>
                <li>hypertension;</li>
                <li>abnormal presentation.</li>
            </ul>
            <h2><strong>Physical Assessment</strong></h2>
            <p>If patient is in labour it may be necessary to assess to see if the cord is protruding, the baby is crowning or if there is are any presenting parts other than the head. Generally speaking do not visualize the perineum unless the mother feels like she has to push or feels that the baby is coming.</p>
            <h2><strong>Considerations</strong></h2>
            <p><strong>Normal labour is divided into three stages: dilation, expulsion and placental</strong>. Will usually proceed without complications. Early contractions are typically 10– 20 minutes apart. By beginning of expulsion, they are 2–3 minutes apart and last 45–90 seconds</p>
            <h2><strong>Other gynecological emergencies</strong></h2>
            <ul>
                <li>Miscarriage (expulsion of fetus prior to 20 weeks gestation)</li>
                <li>Ectopic Pregnancy (abnormal implantation of the fertilized egg outside the uterus causing hypovolemic shock)</li>
                <li>Placenta Previa (abnormal implantation of the placenta where it partially or completely covers the cervical opening)</li>
                <li>Abruptio Placentae (the premature separation of the placenta from the uterine wall)</li>
                <li>Preeclampsia and eclampsia (pregnancy induced hypertension which may lead to seizures)</li>
                <li>Supine hypotensive syndrome (occurs when the patient is laying in such a way ,way, usually supine, that the weight of the uterus compresses the inferior vena cava)</li>
                <li>Braxton hicks’ contractions (false labour contractions that cause no dilation or effacement of the cervix)</li>
                <li>Pre-Term Labour (labour prior to 37 weeks gestation)</li>
            </ul>
        </>
    },
    {
        id: 'acute-abdominal',
        sectionId: 'protocols',
        charts: [],
        title: 'Acute Abdominal Emergencies',
        lastUpdated: '6 Jan 2022',
        body: '',
        markup: <>
            <Abdominal />
            <h2><strong>Indications</strong></h2>
            <p>Abdominal pain in the absence of trauma.</p>
            <h2><strong>Critical History</strong></h2>
            <ul>
                <li>SAMPLE and OPQRST?</li>
                <li>Where is the pain, and what does it feel like?</li>
                <li>When did it start? Has is worsened or stayed the same?</li>
                <li>Any history of trauma?</li>
                <li>Is/was there any nausea, vomiting, diarrhea?</li>
                <li>Any fever?</li>
                <li>Have you ever had this before, how does it compare, what happened last time?</li>
                <li>Any change in diet, new foods or medications?</li>
                <li>Have bowel and bladder movements been normal? Any abnormalities (color, odour, pain with urination or BM, constipation, diarrhea etc)?</li>
                <li>Any blood in urine or stool?</li>
                <li>In female patients</li>
                <li>Any chance of pregnancy?</li>
                <li>Last menstrual period?</li>
            </ul>
            <h2><strong>Key Features</strong></h2>
            <p><strong>Acute Abdomen </strong>– <strong>Abdominal pain that is sharp, severe and typically of rapid onset</strong>. The specific cause is typically difficult to determine.</p>
            <p>Peritonitis – The painful and potentially life-threatening inflammation of the peritoneum caused by infection, disease or trauma (rupture or perforation).</p>
            <p>Abdominal Aortic Aneurism – Symptoms may be vague or obvious including pain, shock or pulsating mass. Handle gently and keep at rest (semi-sitting) during transport.</p>
            <p><strong>Signs and Symptoms of Acute Abdomen include</strong>;</p>
            <ul>
                <li>severe pain (local or diffuse) or abdominal tenderness;</li>
                <li>rigidity, distension or guarding;</li>
                <li>nausea or vomiting;</li>
                <li>signs of shock or of internal bleeding;</li>
                <li>fever.</li>
            </ul>
            <h2><strong>Considerations</strong></h2>
            <p>It is not necessary to diagnose abdominal pain.</p>
            <p>A thorough history and physical examination with treatment of symptoms within the RS scope of practice may be all that the RS is able to do. When in doubt contact an EPOS physician.</p>
        </>
    },
    {
        id: 'infectious-disease-protocol',
        sectionId: 'protocols',
        charts: [],
        title: 'Infectious Response Protocol',
        lastUpdated: '6 Jan 2022',
        body: '',
        markup: <>
            <p>
                These tactics can be used for infectious disease such as; Covid-19, Flu, HIV, TB or bedbugs and other parasites. 
                Having proper PPE and a plan in place is a priority to mitigate contamination to you and your crew.  
                PPE can be improvised to fit the nature of the call and conditions. The responsibility of safety still rests on the responders.
            </p>
            
            <h2>Donning PPE (Personal Protective Equipment)</h2>
            
            <h3><strong>1. Ensure responders are fit for duty.</strong></h3>
            <ul>
                <li>Clean shaven/ trimmed for proper fit</li>
                <li>Hydrated and fed</li>
                <li>Bodily functions voided</li>
                <li>Generally well and confident</li>
            </ul>
            <h3><strong>2.  Inspect all required PPE (Gloves, goggles, tyvec suit, rubber boots, duct tape, disinfectant wipes, large plastic garbage bags)</strong></h3>
            <ul>
                <li>Ensure N95 mask is appropriate for pathogens </li>
                <li>Inspect tyvec suit for size and damage</li>
                <li>Inspect gloves for size and damage</li>
                <li>Ensure ample duct tape</li>
                <li>Inspect goggles for size and damage</li>
                <li>Ensure ample bleach wipes</li>
                <li>Inspect  rubber boots for size and damage</li>
            </ul>
            <h3><strong>3. Don PPE</strong></h3>
            <ul>
                <li>Put on tyvec suit. Do not place hood</li>
                <li>Step into rubber boots. </li>
                <li>If tyvec suit does not have built in feet- put legs on outside of boots and duct tape seal. Give ample room. If boots are built in- taping is not necessary.</li>
                <li>Put on gloves under tyvec suit. In a size larger, put on gloves over suit and seal with duct tape.</li>
                <li>Put on a 2 foot  strip of duct tape down tyvec suit zipper flap. </li>
                <li>Put on a 1 foot strip of duct tape 3 inches to the left of zipper flap. (This is used if suit becomes compromised. )</li>
                <li>Put on N95 mask and pinch nose bridge for fit. Ensure straps make an X before the ear</li>
                <li>Put on goggles</li>
                <li>Put on tyvec hood</li>
                <li>Have “Buddy” inspect donned PPE. </li>
                <p>Note: When taping seals, leave a “courtesy” tab for easy tape removal when doffing. This will minimize risk of tearing suit.</p>
            </ul>

            <h2>Doffing PPE and Procedures Decontamination of Responders</h2>
            <p>Will require: large plastic garbage bag, disinfectant wipes or bleach solution, soap and water for face and bare hands.</p>
            <ol>
                <li>Walk to edge of WARM/ Cold zone</li>
                <li>Have assisting RS in gloves, mask and goggles (minimum)</li>
                <li>Hot Zone RS step inside garbage bags flattened.</li>
                <li>Wipe entire self with bleach wipes putting soiled wipes at feet</li>
                <li>Take off outside gloves</li>
                <li>Wipe hands</li>
                <li>Slowly take down hood</li>
                <li>Wipe hands</li>
                <li>Remove zipper tape and place at feet</li>
                <li>Wipe hands</li>
                <li>Remove boot tape</li>
                <li>Wipe hands</li>
                <li>Unzip suit</li>
                <li>Wipe hands</li>
                <li>Slowly remove suit (roll down) and step out of suit and boots</li>
                <li>Step in cold zone and face warm zone</li>
                <li>Close eyes and remove goggles place in garbage bag</li>
                <li>Wipe hands and face</li>
                <li>Grasp N95 mask straps, close eyes and EXHALE while removing mask place in garbage bag</li>
                <li>Wipe hands and face with soap and water</li>
                <li>Remove gloves, place in garbage bag</li>
                <li>Wipe hands</li>
                <li>Shower</li>
            </ol>
            <p>Assisting RS seals bag and disposes appropriately.</p>
        </>
    },
]