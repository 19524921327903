import React from 'react'
import { ReactComponent as Hypothermia } from './resources/6.1.1.svg'

import { Topic } from '.'

export const environmental: Topic[] = [
    {
        id: 'hypothermia',
        sectionId: 'protocols',
        charts: [<Hypothermia />],
        title: 'Hypothermia and ECMO',
        lastUpdated: '6 Jan 2022',
        body: '',
    },
    {
        id: 'frostbite',
        sectionId: 'protocols',
        charts: [],
        title: 'Frostbite',
        lastUpdated: '6 Jan 2022',
        body: `
        <p><strong>Indications</strong></p>
        <p>Patients exposed to an environment or situation where tissues were exposed to extreme cold and an injury has resulted.</p>
        <p><strong>Critical History</strong></p>
        <ul>
            <li>What was patient exposed to that caused the injury?</li>
            <li>How long was the patient exposed?</li>
            <li>Carefully check other areas of the body that may have been affected.</li>
        </ul>
        <p><strong>Key Features</strong></p>
        <p>Frostbite</p>
        <p>Freezing of tissue with damage caused by crystallization of water in tissue.</p>
        <p>Superficial (frost-nip)</p>
        <p>Skin only involved, white or grey patches, firm but not hard.</p>
        <p>Deep Frostbite</p>
        <p>Skins feels hard and cold, white or grey and may involve entire digit or body part.</p>
        <p><strong>Physical Assessment</strong></p>
        <p>Should include proper circulatory, motor and sensation assessment to determine extent of injury.</p>
        <ul>
            <li>RS should ensure to check other areas of the body that may have been affected.</li>
        </ul>
        `,
    },
    {
        id: 'hyperthermia-heat',
        sectionId: 'protocols',
        charts: [],
        title: 'Hyperthermia and Heat Related Disorders',
        lastUpdated: '6 Jan 2022',
        body: `
        <p><strong>Indications</strong></p>
        <p>Patients exhibiting signs and symptoms of heat related injury.</p>
        <p><strong>Critical History</strong></p>
        <p>What is the mechanism of injury?</p>
        <ul>
            <li>Was the patient working in a hot environment?</li>
            <li>Was the patient hydrating?</li>
            <li>How long was the exposure?</li>
            <li>What were the initial signs and symptoms?</li>
            <li>What is the patient’s medical history?</li>
        </ul>
        <p><strong>Key Features</strong></p>
        <p>Heat Cramps</p>
        <ul>
            <li>Muscular pains and spasms that occur when body loses too much electrolyte during profuse sweating or when inadequate salt is taken into the body.</li>
        </ul>
        `,
    },
    {
        id: 'dive-injury',
        sectionId: 'protocols',
        charts: [],
        title: 'Dive Injury',
        lastUpdated: '6 Jan 2022',
        body: `
        <p><strong>Indications</strong></p>
        <p>Patient exhibiting signs and symptoms of a dive injury with history of diving.</p>
        <p><strong>Critical History</strong></p>
        <p>Consider the diver, buddy, supervisor, dive log, dive computer and depth gage as sources of information.</p>
        <ul>
            <li>Depth? Bottom time? Repetitive Dives?</li>
            <li>Any unusual occurrence during dive?</li>
            <li>Did the patient ascend too rapidly?</li>
            <li>When did the diver first notice problems?</li>
            <li>Does the patient complain of headache, vertigo, visual disturbances, pain, paraesthesia or paralysis? Any seizure activity?</li>
            <li>Does the patient have any underlying medical history?</li>
            <li>Which gas was used (Air, Enriched Air Nitrox or other technical mixture)?</li>
            <li>ENSURE - DIVE GEAR IS TRANSPORTED WITH THE PATIENT</li>
            <li>ENSURE – TO ASSESS DIVE BUDDY FOR DIVE INJURY AND CONSIDER TRANSPORTING AS WELL.</li>
        </ul>
        <p><strong>Physical Assessment</strong></p>
        <p>If AGE is suspected use PPV/BVM with caution as there is risk of forcing more gas into circulation (via ruptured lung tissue).</p>
        <p>Auscultation of chest.</p>
        <p>Do not delay transport of the urgent casualty for detailed on site assessment.</p>
        <p>- Aids in the post dive assessment. If signs and symptoms of dive injury are obvious, conduct during transport to medical facility.</p>
        <p>Recognition of a dive injury is more important than diagnosing between DCS I, DCS II or AGE. Field treatment and transport considerations are essentially the same for all 3.</p>
        <p>Rapid transport to a hyperbaric chamber is the best course of treatment.</p>
        <p>Gather and record an accurate history in writing for the diving physician.</p>
        <p>Transport should positioned supine (unless they meet the criteria outlined for AGE in the flow chart) with high flow O2 (non-rebreather)</p>
        <p><strong>Diving Injuries - Patient Records</strong></p>
        <p>A written record of the diving injury must accompany patient to next level of care. This includes history of the dive up to the injury as well as the normal patient information.</p>
        <p><strong>Considerations</strong></p>
        <ul>
            <li>In many cases and in some of the most serious of dive injuries DCS and AGE, with all their various presentations, will occur concurrently.</li>
            <li>Dive history gathered at scene is useful in establishing a “level of concern” and will be very important to the dive physician as a part of determining chamber treatment.</li>
            <li>All patients suffering from possible barotrauma, air/gas embolism or decompression sickness, no matter how mild the occurrence, <strong>should be seen by a diving physician</strong>.</li>
        </ul>        
        `,
    },
    {
        id: 'sea-sickness',
        sectionId: 'protocols',
        charts: [],
        title: 'Sea Sickness',
        lastUpdated: '6 Jan 2022',
        body: `
        <p><strong>Indications</strong></p>
        <p>Feeling unwell, nausea or vomiting as a result of ship movement.</p>
        <p><strong>Critical History</strong></p>
        <ul>
            <li>Has there been any exposure to a toxic environment? (i.e. CO )</li>
            <li>When did the symptoms start?</li>
            <li>How long has the patient been vomiting for?</li>
            <li>Is the patient diabetic?</li>
            <li>Any underlying medical conditions requiring medication?</li>
            <li>When was the patient’s last fluid and meal intake that was not vomited up?</li>
            <li>Is there any other medical explanation for the patient’s presentation?</li>
            <li>Have any other persons that they have been in contact will with been sick?</li>
        </ul>
        <p><strong>Key Features</strong></p>
        <p>Motion sickness that is particular to the marine environment. It may be completely debilitating or lead to hypovolemic shock.</p>
        <ul>
            <li>Nausea and vomiting preceded by:</li>
            <li>discomfort</li>
            <li>anxiety</li>
            <li>sighing</li>
            <li>yawning</li>
            <li>drowsiness</li>
            <li>increased salivation,</li>
            <li>hyperventilation</li>
            <li>pallor, cold sweating</li>
            <li>dizziness</li>
            <li>headache</li>
        </ul>
        <p><strong>Physical Assessment</strong></p>
        <p>Ensure thorough examination and history taking to ensure that seasickness is the cause of the patient’s presentation.</p>        
        `,
    },
]