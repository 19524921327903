import React from 'react'
import { Typography,Card, Grid, CardMedia, CardContent, Slide } from '@material-ui/core'
// import DownloadIcon from '@material-ui/icons/CloudDownload'
// import homeImage from '../images/photos/home.jpg'
// import homeImage from '../images/googleish/home.png'
// import lookingImage from '../images/googleish/looking.png'
// import homeImage from '../images/manypixel/parachute.svg'
// import lookingImage from '../images/manypixel/sailor.svg'
// import homeImage from '../images/steampunk/home.png'
import bg from '../images/photos/home.jpg'

export const Home: React.FC<{}> = () => {
  return (
      <Grid container spacing={4}>
        <Grid item xs={12} style={{ marginTop: '30px' }}>
          <Slide direction="up" in={true} mountOnEnter unmountOnExit>
            <Typography
              align={'center'}
              gutterBottom={true}
              variant={'h1'}
            >
            THE CANADIAN COAST GUARD
            </Typography>
            </Slide>
            <Slide direction={'up'} in={true}>
              <Typography
              align={'center'}
              variant={'h6'}
              >
                Rescue Specialist Manual
              </Typography>
            </Slide>
        </Grid>
        
        <Grid item xs={12}>
          <Slide direction={'up'} in={true} timeout={400}>
            <Card>
            <CardMedia
              image={bg}
              component='img'
              style={{
                width: '100%',
                height: 'auto',
                maxHeight: '300px',
                margin: 'auto',
              }}
              />
              <CardContent>
                <Typography variant="body2" component="p">
                    This manual serves as a quick reference guide for Canadian Coast Guard Rescue Specialists (RS) with a focus on the operational protocols and procedures for the prehospital care of patients that come into our care in territorial waters of Canada. It reflects the accepted standard of care and RS competencies.
                </Typography>
              </CardContent>
            </Card>
          </Slide>
        </Grid>
      </Grid>
  )
}
