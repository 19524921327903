import { assessment } from './1. assessment'
import { traumaProtocols } from './4. trauma-protocols'
import { medicalProtocols } from './5. medical-protocols'
import { drugs } from './8. drug-monographs'
import { misc } from './9.misc'
import { transport } from './10. transport'
import { communications } from './7. communications'
import { environmental } from './6. environmental'
import { respitory } from './3. respitory'
import { cardiac } from './2. cardiac'

export interface Topic {
    // TODO: Tighten
    sectionId: string
    id: string
    title: string
    body: string
    markup?: JSX.Element
    charts: JSX.Element[]
    lastUpdated: string
}

const sort = (a: Topic, b: Topic) => {
    const nameA = a.title.toUpperCase()
    const nameB = b.title.toUpperCase()
    if (nameA < nameB) {
        return -1
    }
    if (nameA > nameB) {
        return 1
    }
    
    return 0
}

const sectionToTopics: { [key: string]: Topic[]} = {
    'appendix': [...misc, ...communications, ...transport].sort(sort),
    'drugs': drugs,
    'assessment': [...assessment, ...cardiac, ...respitory].sort(sort),
    'protocols': [...traumaProtocols, ...medicalProtocols, ...environmental].sort(sort),
}

export const getTopics = (sectionId: string) => {
    return sectionToTopics[sectionId]
}

export const getTopic = (sectionId: string, topicId: string) => {
    const topics: Topic[] = getTopics(sectionId)

    return topics.find((value) => (
        value.id === topicId
    ))
}

//CASTRACK
