import React from 'react'
import { ReactComponent as Assess111 } from './resources/1.1.1.svg'
import { ReactComponent as Assess112 } from './resources/1.1.2.svg'
import { ReactComponent as Assess113 } from './resources/1.1.3.svg'
import { Topic } from '.'
import { generateTable, generateRow } from '../util'

export const assessment: Topic[] = [
    {
        id: 'patient-assessment-model',
        sectionId: 'assessment',
        title: 'Patient Assessment Model',
        lastUpdated: '6 Jan 2022',
        body: '',
        charts: [
            <Assess112 />,
            <Assess111 />,
            <Assess113 />,
        ]
    },
    {
        id: 'glasgow-coma-scale',
        sectionId: 'assessment',
        title: 'Glasgow Coma Scale',
        lastUpdated: '6 Jan 2022',
        body: `
        <p>
            Measures three different functions of the nervous system to determine current neurological status and monitor any progressive changes. A score of 13 or less indicates the need for rapid transport.
            Note that a motor response in any limb is accepTable.
            The scale is composed of three tests: eye, verbal and motor responses.
            The three values separately as well as their sum are considered. The lowest possible GCS (the sum) is 3 (unresponsive), while the highest is 15 (fully awake person).
            The score is taken after rousing the patient to their highest level of responsiveness.
        </p>
        `,
        markup: generateTable({
            header: generateRow('Glasgow Coma Scale - Response', 'Score'),
            rows: [
                generateRow('Eye Opening Response'),
                generateRow('Spontaneously open, blinking', '4'),
                generateRow('To verbal stimuli or speech', '3'),
                generateRow('To painful stimuli', '2'),
                generateRow('No response', '1'),
                generateRow('Best Verbal Response'),
                generateRow('Oriented', '5'),
                generateRow('Confused, but able to answer questions', '4'),
                generateRow('Inappropriate words', '3'),
                generateRow('Incomprehensible sounds', '2'),
                generateRow('No Response', '1'),
                generateRow('Best Motor Response'),
                generateRow('Obeys commands for movement', '6'),
                generateRow('Localizes pain, purposeful movement', '5'),
                generateRow('Withdraws in response to pain', '4'),
                generateRow('Abnormal flexion (decorticate posturing)', '3'),
                generateRow('Abnormal extension (decerebrate posturing)', '2'),
                generateRow('No Response', '1'),
                generateRow('Total Score ( /15)'),
            ],
        }),
        charts: [

        ]
    },
    {
        id: 'normal-vitals',
        sectionId: 'assessment',
        title: 'Normal Vitals',
        lastUpdated: '6 Jan 2022',
        body: `
        <p><strong>BLOOD PRESSURE</strong></p>
        <p>Normal systolic B/P for adult is considered to be up to 140 mm Hg Diastolic B/P norm 60-90 mm Hg</p>
        <p>Palpated BP (for noisy environment) recorded as systolic/P (e.g. 120/P)</p>
        <p>To approximate minimum systolic B/P by pulses</p>
        <ul>
            <li>palpable radial pulse - at least 90 mm Hg</li>
            <li>palpable femoral or brachial - at least 70 mm Hg</li>
            <li>palpable carotid pulse - at least 60 mm Hg</li>
        </ul>
        <p><strong>TEMPERATURE</strong></p>
        <p>37 Celsius or 98.6 Fahrenheit with some fluctuation</p>
        <p>Below 35∞C considered hypothermic, above 38∞C considered feverish</p>
        <p><strong>PULSE OXIMETRY</strong></p>
        <p>Normal is 96-100%. Below 95% indicates hypoxia &amp; need for supplemental O2.</p>
        <p><strong>CHEST SOUNDS</strong></p>
        <p>Should be present with equal clear air entry. Absent, diminished or unusually noisy chest sounds indicate problem.</p>
        <p>Assess sounds at second intercostal space mid-clavicular line, and fourth intercostal space at mid-axillary line. On back off scapula is preferred for seated patients.</p>
        <p>
            <br>
        </p>
        <p><strong>BLOOD GLUCOSE</strong></p>
        <p><strong>Normal levels are between 4-7 mmol/l</strong></p>
        `,
        charts: [],
        markup: generateTable({
            header: generateRow('Age', 'Heart Rate', 'Respiration', 'Systolic BP'),
            rows: [
                generateRow('New-born', '100-160', '30-60', '50-70'),
                generateRow('1-6 weeks', '100-160', '30-60', '70-95'),
                generateRow('1-6 weeks', '100-160', '30-60', '70-95'),
                generateRow('6 months', '90-120', '20-30', '80-100'),
                generateRow('1 year', '90-120', '20-30', '80-110'),
                generateRow('3 years', '80-120', '18-25', '80-110'),
                generateRow('6 years', '70-100', '18-25', '80-110'),
                generateRow('10 years', '60-90', '15-20', '90-120'),
                generateRow('Adult', '60-80', '12-20', 'Up to 140'),
            ],
        })
    },
    {
        id: 'aeiou-tips',
        sectionId: 'assessment',
        title: 'AEIOU Tips',
        lastUpdated: '6 Jan 2022',
        body: `
        <p><strong>A	</strong>Alcohol</p>
        <p><strong>E	</strong>Epilepsy/electrolyte disturbances</p>
        <p><strong>I   </strong>Infection (sepsis, meningitis, pneumonia, UTI (urinary tract infection)</p>
        <p><strong>O	</strong>O2 (hypoxia), Opiates/Overdose</p>
        <p><strong>U	</strong>Uremia / under-dose</p>
        <p><strong>T  </strong>Trauma (head injury, blood loss (shock), Temperature (hypo or hyperthermia)</p>
        <p><strong>I	</strong>Insulin</p>
        <p><strong>P	</strong>Poisoning, psychosis, pharmacology</p>
        <p><strong>S	</strong>Stroke, seizure</p>        
        `,
        charts: [],
    },
]