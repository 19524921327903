import { Backdrop, Button, Container, TextField, Typography } from '@material-ui/core'
import React, { useState } from 'react'


export const SignInPrompt: React.FC<{ onSignIn: () => void }>= (props) => {
  const [validationError, setValidationError] = useState<string | null>()
  const [password, setPassword] = useState('')
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setPassword(event.target.value)
  }

  const onSubmit: React.FormEventHandler = (event) => {
    event.preventDefault()
    if (password !== 'Rescue2020') {
      setValidationError('Incorrect password')
      return
    }
    setValidationError(null)
    props.onSignIn()
  }

  return   (
    <Backdrop style={{ zIndex: 1000000000, backgroundColor: 'black' }} open={true}>
        <form onSubmit={onSubmit} >
          <Container>
            <Typography variant="h5" gutterBottom>Rescue Specialist Manual</Typography>
            <Typography variant="subtitle1" gutterBottom>Enter RS password to view content</Typography>
            <TextField
              error={!!validationError}
              helperText={validationError}
              name="password"
              type="password"
              label="Password"
              variant="outlined"
              onChange={handleChange}
              value={password}
              style={{ boxSizing: 'content-box' }}
            />
          </Container>
          <Container style={{marginTop: '20px'}}>
              <Button type={'submit'} variant="contained" color="primary" size='large' style={{ float: 'right'}}>
                Submit
              </Button>
          </Container>
        </form>
    </Backdrop>

  )
}