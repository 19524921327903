import React, { useEffect } from 'react'
import { Typography } from '@material-ui/core'
import { useLocation } from 'react-router-dom'

export const DateString: React.FC<{ date: string }> = (props) => {
    const date = new Date(props.date)
    return (
        <Typography gutterBottom={true} variant={'body2'}>
            Last updated on {date.toLocaleDateString("en-US", {year: 'numeric', month: 'long', day: 'numeric'})}
        </Typography>
    )
}

export const ScrollToTop: React.FC = () => {
    const { pathname } = useLocation()
  
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
  
    return null;
  }