import React from 'react'
import { ReactComponent as Shock } from './resources/4.1.1.svg'
import { ReactComponent as Burn } from './resources/4.2.1.svg'
import { ReactComponent as Spine } from './resources/4.3.1.svg'
import { ReactComponent as Spine2 } from './resources/4.3.2.svg'
import { ReactComponent as Musculoskeletal } from './resources/4.4.1.svg'
import { ReactComponent as Entonx } from './resources/4.5.1.svg'
import { ReactComponent as SagerSplint } from './resources/4.6.1.svg'
import { ReactComponent as HeadInjury } from './resources/4.7.1.svg'
import { ReactComponent as ChestInjury } from './resources/4.8.1.svg'
import { ReactComponent as AbdominalInjury } from './resources/4.9.1.svg'
import { ReactComponent as WoundCare } from './resources/4.10.1.svg'

import { Topic } from '.'
import { Typography } from '@material-ui/core'


export const traumaProtocols: Topic[] = [
    {
        id: 'shock',
        sectionId: 'protocols',
        charts: [
        ],
        title: 'Shock',
        lastUpdated: '6 Jan 2022',
        body: '',
        markup: <>
        <Shock />
        <p><strong>NOTES:</strong></p>
        <ol>
            <li>Anticipate that shock may exist and treat before signs are obvious.</li>
            <li>During reassessments ensure bleeding is still controlled.</li>
            <li> Best Position:</li>
        </ol>
        <ul>
            <li>Conscious with breathing problems</li>
            <li>Semi sitting</li>
            <li>Suspected hypovolemic or vasogenic shock, if injuries allow – Supine.</li>
            <li>Unconscious patients – recovery position if injuries allow (to protect airway)</li>
            <li>Pregnant patient – inclined to left side (to prevent supine hypotensive syndrome)</li>
        </ul>
        <h2><strong>Physical Assessment</strong></h2>
        <ul>
            <li>As per protocol.</li>
            <li>As appropriate for trauma or medical.</li>
        </ul>
        <h2><strong>Critical History</strong></h2>
        <ul>
            <li>Trauma vs medical will change your questioning.</li>
            <li>Trauma – goal is to determine the cause of shock – will typically be evident by findings in Primary Survey.</li>
            <li>Medical – see relevant protocol to determine critical history questions.</li>
        </ul>
        <h2><strong>Key Features</strong></h2>
        <ul>
            <li>Restlessness, anxiety, confusion, disorientation, trembling</li>
            <li>Pale, cool, clammy, moist skin – may sweat profusely in severe shock</li>
            <li>Weak, rapid, or irregular pulse</li>
            <li>Shallow, laboured, rapid, or grasping breathing</li>
            <li>Possible fainting, weakness, dizziness, thirst, nausea</li>
            <li>Lusterless eyes, dull expression</li>
            <li>Pulse oximeter reading less than 94% indicates the need for supplemental oxygen - *Oxygen should never be withheld from an apparently hypoxic patient, regardless of SPO2 reading as the machine loses accuracy as blood pressure drops.</li>
            <li>Drop in blood pressure, dilated pupils, and unconsciousness are late signs</li>
        </ul>
        <h2><strong>Internal Hemorrhage – Recognition</strong></h2>
        <ul>
            <li>Consider patient history. May be traumatic or spontaneous medical cause</li>
            <li>General signs and symptoms of shock</li>
            <li>Pain, tenderness, swelling, or discoloration where injury is suspected</li>
            <li>The chest, pelvis and femurs are all areas that hold a high volume of blood</li>
            <li>Bleeding from mouth, rectum, or other natural body openings</li>
        </ul>
        <h2><strong>Considerations</strong></h2>
        <ul>
            <li>Occurs secondary to trauma or illness due to loss of fluid volume, loss of vascular tone, failure of the heart to pump adequately or a lack of oxygen/impaired respiration.</li>
            <li>Cold blood takes much longer to clot so a warm environment is critical.</li>
            <li>Initially the body will compensate and signs will be subtle. If cause not corrected then shock becomes progressive / de-compensatory with more obvious signs, eventual permanent damage and death may result.</li>
        </ul>
        <p><strong>Common types of shock are:</strong></p>
        <ul>
            <li>Anaphylactic</li>
            <li>Cardiogenic</li>
            <li>Hypovolemic</li>
            <li>Neurogenic</li>
            <li>Psychogenic</li>
            <li>Respiratory</li>
            <li>Spinal</li>
            <li>Septic</li>
        </ul>
        </>,
        
    },
    {
        id: 'burns',
        sectionId: 'protocols',
        charts: [
        ],
        title: 'Burns',
        lastUpdated: '6 Jan 2022',
        body: '',
        markup: <>
            <Burn />
            <h2><strong>Critical History</strong></h2>
            <ul>
                <li>Is the burn caused by thermal, electrical, chemical or radiation?</li>
                <li>If chemical – what is the chemical? Is there an MSDS available?</li>
                <li>If electrical – type of current (AC or DC) and voltage? Are there entry and exit wounds?</li>
                <li>How long ago was the patient burned?</li>
                <li>What was the exposure time?</li>
                <li>What is are the body surface area(s) affected?</li>
                <li>What was done prior to the RS arrival?</li>
                <li>Is the patient very young, very old, or have any complicating injuries or conditions?</li>
            </ul>
            <h2><strong>Key Features</strong></h2>
            <p><strong>THERMAL</strong></p>
            <p><strong>Superficial (1</strong><sup>st</sup> Degree)</p>
            <ul>
                <li>Dry, painful, red, blanches easily, no blistering</li>
                <li>Epidermis only</li>
            </ul>
            <p><strong>Partial thickness (2</strong><sup>nd</sup> Degree)</p>
            <ul>
                <li>Moist surface, red or mottled, blisters, painful and blanches to pressure</li>
                <li>Involves epidermis and part of the dermis</li>
            </ul>
            <p><strong>Full thickness (3</strong><sup>rd</sup> Degree)</p>
            <ul>
                <li>Characterized by dry, hard and leathery, pearly white or charred skin with decreased pain (destruction of nerve endings)</li>
                <li>Involves epidermis and all of the dermis</li>
            </ul>
            <p><strong>CHEMICAL</strong></p>
            <p><strong>Fourth Degree</strong></p>
            <ul>
                <li>Appears as full thickness but involves muscle, tendons, or bone</li>
                <li>Any of the signs and symptoms from 1<sup>st</sup> or 2<sup>nd</sup> degree</li>
                <li>Vision changes if chemical burn to the eyes</li>
                <li>Cough or short of breath</li>
                <li>Vomiting</li>
            </ul>
            <p><strong>INHALATION</strong></p>
            <ul>
                <li>Respiratory distress, burns around mouth, hoarse voice or stridor, cough (with or without sputum)</li>
                <li>May take hours for symptoms to surface</li>
            </ul>
            <h2><strong>Physical Assessment</strong></h2>
            <p>Primary concern is for airway and respiration.</p>
            <p>Critical intervention is to cool/flush. Insure patient does not start shivering. If they do, stop cooling or reduce area of cooling.</p>
            <p>SP02 monitor should not be used in patients with any chance of inhalation injury as it may read 100% due to CO levels.</p>
        </>,
    },
    {
        id: 'spinal-motion-restriction',
        sectionId: 'protocols',
        charts: [
        ],
        title: 'Spinal Motion Restriction and Long Term Packaging',
        lastUpdated: '6 Jan 2022',
        markup: <>
            <Spine />
            <h2><strong>Indications for Spinal Motion Restriction (SMR)</strong></h2>
            <p>Patients suspected of having a spinal injury. and may require immobilization. RS must have completed the primary survey prior to ruling out spinal.</p>
            <p>When in doubt, apply spinal motion restriction</p>
            <h2><strong>Contraindications for COLLAR APPLICATION</strong></h2>
            <ul>
                <li>Patients with known vertebral disease (i.e. severe arthritis or ankylosing spondylitis – these people have fused vertebrae) only if the collar does not fit naturally</li>
                <li>Penetrating neck injuries or if neck swelling is present or expected</li>
                <li>Unable to apply collar due to some other injury</li>
                <li>If the collar interferes with airway management</li>
                <li>Application of the collar causes more pain</li>
                <li>If patient refuses or it increases agitation to a point where the cervical spine is being compromised</li>
            </ul>
            <p>In these cases you are still immobilizing as best as possible without the use of a collar.</p>
            <p><strong>NOTE ** Although not contraindicated, it is not necessary to spinal a patient with a gunshot wound (GSW) or stab wound unless they present with signs and symptoms of spinal injury.</strong></p>
            <h2><strong>Critical History</strong></h2>
            <ul>
                <li>Questions should be asked to determine if the patient should be immobilized – base your questions on the flowchart protocol on the previous page and the contraindications above.</li>
            </ul>
            <h2><strong>Key Features of Spinal Injury</strong></h2>
            <ul>
                <li>Numbness or tingling in the extremities.</li>
                <li>Paralysis in the extremities.</li>
                <li>Point tenderness at any point along the spine.</li>
            </ul>
            <h2><strong>Physical Assessment</strong></h2>
            <ul>
                <li>Palpate along the length of the spine to look for point tenderness.</li>
                <li>Have the patient move their head from left to right – ensure no pain or resistance.</li>
                <li>Assess motor and sensory on the extremities.</li>
            </ul>
            <h2><strong>Considerations</strong></h2>
            <p><strong>Stages of spinal patient care:</strong></p>
            <ol>
                <li>Initial treatment as found at the scene, including extrication.</li>
                <li>Short term transport to a stable environment (e.g. rescue vessel)</li>
                <li>Long term transport (over 30 mins) to medical assistance.</li>
            </ol>
            <p><strong>Hard Collar</strong></p>
            <ul>
                <li>The head and neck must be in neutral spinal alignment (i.e. supine or seated upright).</li>
                <li>If rescuer meets resistance to this alignment or the casualty experiences pain then immobilise the neck in positonposition found (without collar)</li>
                <li>Proper collar sizing is critical. Too short a collar will not properly immobilize the neck while too long will cause hyperextension</li>
            </ul>
            
            <Typography variant={'h5'} gutterBottom={true}>
                Spinal - Long Term Packaging > 30mins
            </Typography>
            <Spine2 />
            <h2><strong>Other Long-Term Care Considerations</strong></h2>
            <p>The objective of packaging for long-term transport is to minimize patient aggravation during transport and to prevent bedsores (decubitus ulcers).</p>
            <p><strong>Assessment and Documentation </strong>– Record details accurately. Reassess and note all changes. Give notification to hospital and seek medical advice.</p>
            <p><strong>The Environment </strong>– Make location comfortable for patient and accessible for caregiver.</p>
            <p><strong>Rest </strong>– Rest is conducive to healing. Efforts to reduce pain and maintain body temperature will help promote rest.</p>
            <p><strong>Respiratory Care </strong>– Inactivity can result in accumulation of fluids in the lungs. Encourage deep breaths &amp; cough frequently to maintain respiratory function.</p>
            <p><strong>Positioning </strong>– Maintain position of greatest comfort as injuries allow.</p>
            <p><strong>Skin Care for Spinal </strong>– Padding as noted on flow chart, promote circulation by passive movement and massage, alternate stretcher positions, and keep bedding clean and dry.</p>
            <p><strong>Maintain Hydration </strong>– Patients with no paralysis or a delayed transport who can tolerate should drink small amounts fluid regularly. If surgery likely within 8 hours nothing by mouth.</p>
            <p><strong>Maintain Nutrition </strong>–– Patients with no paralysis or a delayed transport who can tolerate can eat small amounts of high energy food regularly if they want (cereals, bread, soups). If surgery within 8 hours, nothing by mouth.</p>
            <p><strong>Urination and Bowel Care </strong>– If required use bedpans, urinals or improvised methods (e.g. diapering). Provide privacy. Check bedpan contents for abnormalities.</p>
            <p><strong>Administering Medication </strong>– If any question, consult with medical direction. Have all documentation and list of available medications at hand.</p>        
        </>,
        body: ``,
    },
    {
        id: 'musculoskeletal-injuries',
        sectionId: 'protocols',
        charts: [],
        title: 'Musculoskeletal Injuries',
        lastUpdated: '6 Jan 2022',
        body: '',
        markup:<>
            <Musculoskeletal />
            <h2><strong>Indications</strong></h2>
            <p>Suspected musculoskeletal injury (sprain, strain, fracture, dislocation).</p>
            <h2><strong>Critical History</strong></h2>
            <ul>
                <li>Consider type and degree of force (mechanism of injury). May be open or closed and may alert you to other underlying injuries – possibly internal.</li>
            </ul>
            <h2><strong>Key Features</strong></h2>
            <ul>
                <li>Loss of function of the affected part or guarding</li>
                <li>instability</li>
                <li>Pain or tenderness</li>
                <li>Swelling</li>
                <li>Discoloration</li>
                <li>Deformity, angulation or shortening</li>
                <li>weakness</li>
                <li>If a fracture –&nbsp;fracture can be open or closed</li>
                <li>Grating or crepitus</li>
                <li>Decreased distal circulation</li>
            </ul>
            <h2><strong>Physical Assessment</strong></h2>
            <p><strong>Sprains and Strains&nbsp;</strong></p>
            <ul>
                <li>Consider <strong>R.I.C.E.: Rest</strong>; <strong>Immobilization </strong>– support and limit aggravation; <strong>Cold - </strong>cold to reduce swelling (10 minutes on / 5 off); <strong>Elevation </strong>– if injuries allow.</li>
                <li><strong>If severe, treat as fracture</strong>. Immobilize to transport.</li>
            </ul>
            <h2><strong>Considerations</strong></h2>
            <p>It is more critical to assess severity of injury than “diagnose” the specific type of injury. The objective of splinting is to prevent aggravation, reduce pain and minimize complications. After an injury, blood edema (fluid resulting and inflammation or injury) may accumulate in the compartment, the tough walls of fascia cannot easily expand, and compartment pressure rises, preventing adequate blood flow tissues inside&nbsp;the compartment. severe tissue damage can result, with loss of body function or even death. Continually assess for signs of compartment syndrome: <strong>The objective of splinting is to prevent aggravation, reduce pain and minimize complications.</strong></p>
            <p><strong>Continually assess for signs of compartment syndrome:</strong></p>
            <ul>
                <li>The pain is more intense than what would be expected from the injury itself.</li>
                <li>There may also be tingling or burning sensations (paraesthesia) in the skin.</li>
                <li>The muscle may feel tight or full.</li>
                <li>Numbness or paralysis are late signs of compartment syndrome.</li>
            </ul>
        </>,
    },
    {
        id: 'entonox',
        sectionId: 'protocols',
        charts: [],
        title: 'Entonox',
        lastUpdated: '6 Jan 2022',
        body: '',
        markup: <>
        <Entonx />
        <h2><strong>Indications</strong></h2>
        <ul>
            <li><strong>To ease pain</strong>. <strong>Primarily indicated for traumatic injuries </strong>to the extremities.</li>
            <li><strong>Used only after completion of assessments to rule out contraindications</strong>.</li>
            <li>May be used if required during pregnancy or labour.</li>
        </ul>
        <h2><strong>Contraindications</strong></h2>
        <ul>
            <li><strong>P	</strong>Pneumothorax</li>
            <li><strong>A	</strong>Air embolism</li>
            <li><strong>I	</strong>Inability to comply</li>
            <li><strong>N	</strong>Nitroglycerin in the last 5 minutes</li>
            <li><strong>D	</strong>Decompression sickness</li>
            <li><strong>I	</strong>Inhalation injury</li>
            <li><strong>E	</strong>Enclosed space</li>
            <li><strong>S	</strong>Shock</li>
        </ul>
        <h2><strong>Cautions</strong></h2>
        <p><strong>S	</strong>Shock</p>
        <p><strong>M	</strong>Maxillofacial injuries, head injury, earache</p>
        <p><strong>A	</strong>Abdominal distention or obstruction</p>
        <p><strong>D	</strong>Depressant drugs or alcohol intoxication <strong>M	</strong>Maxillofacial injuries, head injury, earache</p>
        <p><strong>C	</strong>COPD</p>
        <h2><strong>Physical Assessment</strong></h2>
        <p>Proper assessment of the injury (expose, examine and pulse, motor, sensory check) all must be completed prior to Entonox.</p>
        <p>The RS must auscultate the chest to rule out contraindications.</p>
        <h2><strong>Considerations</strong></h2>
        <p>If the patient is in pain it is ideal important to administer Entonox prior to splinting if possible.</p>
        <p>If temperature is below -6°C. the N2O liquefies in a process called lamination. If this occurs a high concentration of O2 will be delivered first with little analgesic effect, but as the cylinder empties the mixture will become progressively more potent and hypoxic as it approaches 100% N2O. If a cylinder has been exposed to cold below -6° C it should be warmed for 5 minutes in a 37° C water bath or for 2 hours in a room at 15° C. It should then be inverted three times before use.</p>
        </>,
    },
    {
        id: 'sager',
        sectionId: 'protocols',
        charts: [
        ],
        title: 'Sager',
        lastUpdated: '6 Jan 2022',
        body: '',
        markup:<>
            <SagerSplint />
            <h2><strong>Indications</strong></h2>
            <ul>
                <li>Mid-shaft femur fractures only.</li>
                <li>Apply to stable patient only after assessments OR unstable patient once transport is underway.</li>
            </ul>
            <p>Objective is to stabilize the injury and provide patient relief.</p>
            <h2><strong>Contraindications</strong></h2>
            <p>Fractures other than mid shaft femur.</p>
            <h2><strong>Critical History</strong></h2>
            <ul>
                <li>SAMPLE and OPQRST</li>
                <li>What was the mechanism of injury?</li>
                <li>Is spinal a consideration?</li>
                <li>Is this patient rapid transport?</li>
                <li>Is the fracture open or closed?</li>
                <li>Does pain require use of Entonox?</li>
            </ul>
            <h2><strong>Key Features</strong></h2>
            <p>Mid-shaft femur fracture</p>
            <ul>
                <li>Pain at site</li>
                <li>Redness, bruising or swelling at site</li>
                <li>Inability to move</li>
                <li>Guarding with muscles – usually painful</li>
                <li>Deformity or shortening</li>
                <li>Numbness or weakness</li>
                <li>Decreased distal circulation</li>
            </ul>
            <h2><strong>Physical Assessment</strong></h2>
            <p>Cut and expose all injuries</p>
            <p>Absent distal circulation or bilateral femur fracture is rapid transport category Dress any open wounds prior to splinting, however, no encircling bandages under splints.</p>
            <h2><strong>Considerations</strong></h2>
            <p>Blood loss from a femur fracture can be considerable (up to 1.5L) watch for signs of shock.</p>
            <h2><strong>Other Splinting Materials</strong></h2>
            <p>Splinting materials include: air splints; vacuum splints; padded wooden splints; spine or fracture board; blanket/pillow splint; SAM splint; Speed splint; patients’ body; improvised materials.</p>
        </>,
    },
    {
        id: 'head-injuries',
        sectionId: 'protocols',
        charts: [
        ],
        title: 'Head Injuries',
        lastUpdated: '6 Jan 2022',
        body: '',
        markup:<>
            <HeadInjury />
            <h2><strong>NOTES:</strong></h2>
            <ul>
                <li> ICP – Intracranial pressure</li>
                <li> Cushing’s Triad are signs and symptoms of ICP</li>
                <li>High or rising BP</li>
                <li>Decreased or erratic respirations</li>
                <li>Slowing heart rate</li>
            </ul>
            <h2><strong>Critical History</strong></h2>
            <ul>
                <li>What was the mechanism of injury?</li>
                <li>How much force was applied and in what direction?</li>
                <li>What is the level of consciousness? Was there any Loss of Consciousness? If so, for how long?</li>
                <li>Are battle signs or raccoon eyes present?</li>
                <li>Is CSF present from the ears/nose?</li>
                <li>Any signs of depressed or penetrating head injury?</li>
            </ul>
            <h2><strong>Key Features</strong></h2>
            <p><strong>Skull Fracture</strong></p>
            <ul>
                <li>May be open or closed.</li>
                <li>If open a risk of infection exists. If closed potential exists for swelling and increasing intra-cranial pressure.</li>
                <li>May be associated leakage of cerebrospinal fluid from ears or nose, Battle’s sign or raccoon eyes.</li>
            </ul>
            <p><strong>Concussion</strong></p>
            <ul>
                <li>Temporary disturbance of normal brain function.</li>
                <li>Effects ranging from confusion to complete unresponsiveness appear immediately or soon after impact and then disappear.</li>
            </ul>
            <p><strong>Contusion / ICP</strong></p>
            <ul>
                <li>Bruising and swelling of the brain that can accompany concussion.</li>
                <li>Permanent brain damage can result and Intra-Cranial Pressure (ICP) may progress leading to permanent disability or death.</li>
            </ul>
            <h2><strong>Signs and Symptoms of Contusion / ICP may include:</strong></h2>
            <p>Decreased or falling level of conscious, sometimes following a lucid interval</p>
            <ul>
                <li>High or rising blood pressure</li>
                <li>Bradycardia (slow pulse)</li>
                <li>Respiratory depression or irregular breathing</li>
                <li>Non-reactive or unequal pupil(s)</li>
                <li>Paralysis, may be total or partial</li>
                <li>May have blood or fluid from nose or ears</li>
                <li>Nausea / vomiting</li>
            </ul>
            <h2><strong>Considerations</strong></h2>
            <ul>
                <li>Increased intracranial pressure is the rise in pressure around the brain. In the case of trauma this is caused by swelling or bleeding in the cranial cavity. Once pressure begins to build it puts pressure on the centers of the brain including those controlling BP, respirations and heart rate. This is the reason for the Cushing’s Triad signs and symptoms. If treatment is delayed permanent brain damage or death may result. Need something re treatment of concussion (i.e. how often to wake up, etc)?Ensure sleeping patients with concussions are woken up every 15 minutes</li>
            </ul>
        </>,
    },
    {
        id: 'chest-injuries',
        sectionId: 'protocols',
        charts: [
        ],
        title: 'Chest Injuries',
        lastUpdated: '6 Jan 2022',
        body: '',
        markup:<>
            <ChestInjury />
            <h2><strong>Indications</strong></h2>
            <p><strong>Open (penetrating) or closed (blunt) chest trauma.</strong></p>
            <h2><strong>Critical History</strong></h2>
            <p><strong>If penetrating</strong></p>
            <ul>
                <li>If penetrating object is a weapon – ensure safe scene.</li>
                <li>What is/was the penetrating object?</li>
                <li>What is the length of the object? Could it have reached the spine? – If so, patient is spinal.</li>
                <li>What is the point and angle of entry?</li>
                <li>Is there an exit wound?</li>
                <li>When did it happen? What has been done for the patient prior to our arrival?</li>
                <li>How has patient condition changed since injury occurred?</li>
            </ul>
            <p><strong>If blunt</strong></p>
            <ul>
                <li> What was the force involved?</li>
                <li>What is the position of impact?</li>
                <li>When did it happen? What has been done for the patient prior to our arrival?</li>
                <li>How has patient condition changed since injury occurred?</li>
            </ul>
            <h2><strong>Key Features</strong></h2>
            <p><strong>Simple Rib Fracture</strong></p>
            <ul>
                <li>Not usually life threatening unless damage to underlying lung.</li>
                <li>Very painful, especially on movement. Rapid shallow breathing is common.</li>
                <li>Rule out serious underlying injury; provide broad support as with sling and swathe.</li>
            </ul>
            <p><strong>Chest Injuries - Flail Chest</strong></p>
            <ul>
                <li>Typically involves a significant blunt trauma.</li>
                <li>Two or more ribs broken in two places, resulting in an unstable segment that may move paradoxically to the rest of the chest wall.</li>
                <li><strong>Objective is to support ABC’s and stabilize unstable segments</strong>. Use hand pressure initially and secure bulky padding over segment to reduce movement.</li>
            </ul>
            <p><strong>Pericardial Tamponade</strong></p>
            <ul>
                <li>Traumatic injury (most often penetrating) injury to the heart causing bleeding within the heart sac, reducing the hearts ability to pump.</li>
                <li>Watch for signs of profound shock, a narrowing pulse pressure, distended neck veins, etc. Support ABC’s and begin rapid transport to medical aid.</li>
            </ul>
            <p><strong>Pulmonary and Cardiac Contusions</strong></p>
            <ul>
                <li>Caused by blunt trauma to the chest wall.</li>
                <li>May be progressive as swelling occurs. Assess carefully and monitor for changes during transport to medical aid.</li>
            </ul>
            <p><strong>Thoracic Aorta Tear</strong></p>
            <ul>
                <li>Deadly injury typically caused by sudden deceleration.</li>
                <li>ABC’s and basic life support during rapid transport.</li>
            </ul>
            <p><strong>Blast Injury</strong></p>
            <ul>
                <li>Pressure wave enters airway causing lung damage and rupture of alveoli.</li>
                <li><strong>Injury may not at first be obvious. Progressive and potentially life threatening</strong>.</li>
                <li>If breathing distressed provide positive pressure ventilation but do not over- ventilate.</li>
                <li>May also cause hemo or pneumo-thorax.</li>
            </ul>
            <h2><strong>Physical Assessment</strong></h2>
            <p>Mechanism of injury is key to assessment. Spinal should be considered when penetrating object is of length that would allow for contact with the spinal cord, any gun-shot wound that hits the spine and any major blunt trauma.</p>
            <p>Assessment should include auscultation at the end of the primary, however, do not delay transport to perform this.</p>
            <h2><strong>Considerations</strong></h2>
            <p>On reassessments check treatments to ensure they are effective and continually monitor breath sounds.</p>
            <p>Assisting with respirations too early will compound the potentially developing pneumothorax as you increase the positive pressure within the lungs. This is the reason for waiting until you see decreased LOC or signs of cyanosis before assisting with respirations.</p>
            <p>Pneumothorax and tension pneumothorax require <strong>needle decompression </strong>to allow the air to exit the pleural space. This procedure can be performed by a 442 Squadron SAR Tech or an EHS Advanced Life Support or Critical Care Paramedic.</p>
        </>,
    },
    {
        id: 'abdominal-injuries',
        sectionId: 'protocols',
        charts: [
        ],
        title: 'Abdominal Injuries',
        lastUpdated: '6 Jan 2022',
        body: '',
        markup: <>
            <AbdominalInjury />
            <h2><strong>Indications</strong></h2>
            <p>Traumatic injuries to the abdominal cavity.</p>
            <h2><strong>Critical History</strong></h2>
            <ul>
                <li>What is the nature of the mechanism of injury – is it major – does it warrant spinal?</li>
                <li>Is the wound open or closed? If open and penetrating determine the penetrating object and its length, position and angle of entry.</li>
                <li>Are there any protruding contents?</li>
                <li>If the trauma was blunt – what was the force and area involved?</li>
                <li>Is there any bruising, pain, rebound tenderness, radiation of pain etc?</li>
            </ul>
            <h2><strong>Key Features</strong></h2>
            <ul>
                <li>Pain at injury site, (on palpation, or rebound tenderness), may radiate.</li>
                <li>Bruising, redness</li>
                <li>Nausea, vomiting</li>
                <li>Signs and symptoms of internal bleeding (redness, bruising, shock, pain)</li>
            </ul>
            <h2><strong>Physical Assessment</strong></h2>
            <p>Injuries to the upper quadrants of the abdomen or lower rib cage can be associated with massive blood loss due to rupture of the liver or spleen.</p>
            <h2><strong>Considerations</strong></h2>
            <ul>
                <li>Wound may be either open or closed.</li>
                <li>Severe risk of shock and infection due to damage to internal organs.</li>
                <li><strong>Consider mechanism of injury and be suspicious of internal bleeding.</strong></li>
            </ul>
        </>,
    },
    {
        id: 'wound-care',
        sectionId: 'protocols',
        charts: [
        ],
        title: 'Wound Care',
        lastUpdated: '6 Jan 2022',
        body: '',
        markup:<>
            <WoundCare />
            <h2><strong>Indications</strong></h2>
            <p>Management of wounds.</p>
            <h2><strong>Critical History</strong></h2>
            <ul>
                <li>What is the mechanism of injury?</li>
                <li>Is the injury immediate, if not, how long since the injury occurred?</li>
                <li>If not immediate – are there any signs of infection?</li>
                <li>Does the wound have foreign material in it, or was it caused by and infectious object?</li>
                <li>If amputation – locate the amputated part.</li>
                <li>If eye injury – is vision affected?</li>
                <li>When was the patients’ last tetanus shot? (If not in the past 10 years they should receive a booster within 24hrs)</li>
            </ul>
            <h2><strong>Key Features</strong></h2>
            <p><strong>Infection of Wound Infection</strong></p>
            <ul>
                <li>Redness, swelling, tenderness, puss discharge, heat around area, swollen lymph nodes, fever, and red streaks extending from area.</li>
                <li>Some redness/swelling is normal.</li>
            </ul>
            <h2><strong>Physical Assessment / Treatment</strong></h2>
            <p><strong>Infection</strong></p>
            <ul>
                <li>If more severe signs, refer to medical aid. Infection may progress rapidly &amp; have serious consequences. If remote seek advice and consider that antibiotics from ship’s pharmacy may be accessed on doctor’s orders</li>
            </ul>
            <p><strong>Refer for Stitches</strong></p>
            <ul>
                <li>In general stitches are required for any wound that gapes, particularly if near a joint, on hands or feet, in a hairy area, around the face or where scarring will be a problem.</li>
                <li>The sooner the better for stitches to be effective (ideally within 4 hours).</li>
            </ul>
        </>,
    },
]