import { Topic } from '.'


export const drugs: Topic[] = [
    {
        id: 'pharmaceuticals',
        sectionId: 'drugs',
        charts: [],
        title: 'Pharmaceuticals and Assisted Medications',
        lastUpdated: '6 Jan 2022',
        body: `
        <p><strong>Assisted Medications</strong></p>
        <p>It is not the role of the RS or first aid personnel to prescribe medications. It is however, appropriate in some cases to assist with certain meds to relieve acute symptoms of a previously diagnosed ailment. Read and follow directions on packaging and follow protocols.</p>
        <p><strong>Always refer to the Drug Monographs in Section 8 </strong>or the <strong>Compendium of Pharmaceuticals and Specialties (CPS) </strong>when giving medications so that you are aware of its expected actions as well as its side effects.</p>
        <p><strong>The Six Rights</strong></p>
        <p><strong>Safe and appropriate use is in part provided by ensuring that the five six rights are followed:</strong></p>
        <ol>
            <li>The Right Medication – check, then recheck the label</li>
            <li>The Right Person – only for the person prescribed</li>
            <li>The Right Dose – never more nor less than directed</li>
            <li>The Right Time – how often, before or after meals, etc.</li>
            <li>The Right Route – follow instructions as to method</li>
            <li>The Right Documentation – complete the paperwork</li>
        </ol>
        `,
    },
    {
        id: 'activated-charcoal',
        sectionId: 'drugs',
        charts: [],
        title: 'Activated Charcoal',
        lastUpdated: '6 Jan 2022',
        body: `
        <p><strong>RS Indications</strong></p>
        <p><strong>Suspected Paralytic Shellfish Poisoning (PSP)</strong></p>
        <p>Always consult Poison Control in any poisoning other than PSP.</p>
        <p><strong>Contraindications</strong></p>
        <p>Patient unable to swallow. Unconscious Patient. Not effective for corrosives or petroleum products.</p>
        <p><strong>Adverse Effects</strong></p>
        <p>Constipation or blockage Diarrhea</p>
        <p>Black stool Nausea / vomiting</p>
        <p>More serious, but rare, side effects are a slowing or blockage of the intestinal tract, regurgitation into the lungs, and dehydration.</p>
        <p><strong>Action</strong></p>
        <p>Prevents absorption of some poison types</p>
        <p><strong>Dosage and Administration</strong></p>
        <p>1 bottle contains 50 g activated charcoal oral suspension in purified water (250mL).</p>
        <p>Shake well</p>
        <p>DOSE (child to adult) is 65-250 ml (13-50 g of activated charcoal). Use as directed by poison control.</p>
        `,
    },
    {
        id: 'asa',
        sectionId: 'drugs',
        charts: [],
        title: 'ASA',
        lastUpdated: '6 Jan 2022',
        body: `
        <p><strong>RS Indications</strong></p>
        <p>Chest pain or atypical symptoms consistent with ACS. Cardiac Chest Pain</p>
        <p><strong>Contraindications</strong></p>
        <p>Allergy to ASA or other anti-inflammatory (i.e. Advil)</p>
        <p>Patients with history of Asthma and or nasal polyps (this is a contraindication in TG if patient has had ASA exacerbation of asthma in the past)</p>
        <p>Active gastrointestinal bleed or recent head injury or stroke</p>
        <p><strong>Precautions</strong></p>
        <p>Recent surgery Possibility of pregnancy</p>
        <p><strong>Adverse Effects </strong>Heartburn Nausea / vomiting Wheezing</p>
        <p><strong>Action</strong></p>
        <p>Platelet inhibitor</p>
        <p>Blocks platelet aggregation</p>
        <p><strong>Pharmacology</strong></p>
        <p>Onset 15-30 minutes (if chewed) 1 hour if swallowed Peak 1-2 hours</p>
        <p>Half-life 3.5-4.5 hours</p>
        <p>Duration 4-6 hours</p>
        <p><strong>Dosage and Administration</strong></p>
        <p>Chewable tablets</p>
        <p>Max dose is 160mg ( 162 is acceptable when using 81 mg tablets) RS may top patient up if they have not had the full dose that day.</p>        
        `,
    },
    {
        id: 'diphenhydramine',
        sectionId: 'drugs',
        charts: [],
        title: 'Diphenhydramine (Anti-histamine /Allernix)',
        lastUpdated: '6 Jan 2022',
        body: `
        <p><strong>RS Indication</strong></p>
        <p>Treatment for mild allergic reaction or as follow-up to Epinephrine in cases of anaphylaxis.</p>
        <p><strong>Contrindications</strong></p>
        <p>Allergy to Diphenhydramine (Benadryl, Allernix etc)</p>
        <p>Acute asthma</p>
        <p><strong>Precautions </strong>COPD patients History of asthma</p>
        <p>Patients with urinary obstruction Elderly</p>
        <p>Hypertension CAD</p>
        <p>May cause paradoxical excitation in children</p>
        <p><strong>Adverse Effects (long list on TG)</strong></p>
        <p>Drowsiness, Dizziness</p>
        <p>Epigastric distress, anorexia, nausea, vomiting, diarrhea, constipation.</p>
        <p>Thickening of bronchial secretions, tightness of chest or throat and wheezing, nasal stuffiness, dryness of mouth</p>
        <p>Nervousness Excitation</p>
        <p>Nausea / vomiting Diarrhea Constipation Hypotension, Tachycardia,</p>
        <p>Palpitations Headache</p>
        <p>Urinary retentions Blurry vision</p>
        <p>Alcohol or other depressant drugs may potentiate effects</p>
        <p><strong>Action</strong></p>
        <p>Blocks further histamine release, (but does not reverse histamines or other chemicals already released as part of allergic reaction)</p>
        <p>Blocks histamine activity at the receptor sites</p>
        <p><strong>Dosage and Administration</strong></p>
        <p>child 12.5-25 mg</p>
        <p>adult 25-50 mg</p>
        <p>1-4 hours</p>
        `,
    },
    {
        id: 'entonox',
        sectionId: 'drugs',
        charts: [],
        title: 'Entonox',
        lastUpdated: '6 Jan 2022',
        body: `
        <p><strong>RS Indications</strong></p>
        <p>To ease pain.</p>
        <p>Primarily for traumatic injuries to the extremities, but may also be used in cases of chest pain, burns, kidney stones, appendicitis, ectopic pregnancy etc.</p>
        <p>(Only when contraindications have been ruled out).</p>
        <p><strong>Contraindications	</strong></p>
        <p>P&nbsp;&nbsp;Pneumothorax or Flail</p>
        <p>A&nbsp; Air emboli</p>
        <p>I Inability to comply</p>
        <p>N Nitroglycerin in the last 5 minutes</p>
        <p>I Inhalation injury</p>
        <p><strong>Cautions</strong></p>
        <p>S Shock</p>
        <p>A Abdominal distension</p>
        <p>D Depressant drugs</p>
        <p>M Maxillofacial injuries, head injury, earache C COPD</p>
        <p>F Facial injuries, head injury, earache</p>
        <p><strong>Adverse Effects</strong></p>
        <p><strong><span class="ql-cursor">﻿</span></strong>Nausea / vomiting Drowsiness Dizziness Giddiness</p>
        <p><strong>Actions</strong></p>
        <p>Analgesic gas - CNS depressant with rapid pain relief. Works specifically on the CNS. Reasonable degree of pain control; compatible with other drugs; not toxic within time frame of field use</p>
        <p><strong>Pharmacology</strong></p>
        <p><em>Onset</em> Rapid</p>
        <p><em>Peak</em> Immediate</p>
        <p><em>Duration</em> Effects dissipate rapidly</p>
        <p><strong>Dosage and Administration</strong></p>
        <p>Self-administered for pain relief. Discontinue use if;</p>
        <ul>
            <li>patient denies due to side effects</li>
            <li>patient becomes too drowsy to self-administer</li>
            <li>or requires high flow O2</li>
        </ul>
        `,
    },
    {
        id: 'epinephrine',
        sectionId: 'drugs',
        charts: [],
        title: 'Epinephrine',
        lastUpdated: '6 Jan 2022',
        body: `
        <p><strong>RS Indications</strong></p>
        <p>Anaphylaxis</p>
        <p><strong>Contraindications</strong></p>
        <p>None</p>
        <p><strong>Precautions</strong></p>
        <p>Elderly</p>
        <p>Diabetes</p>
        <p>Cardiovascular disease</p>
        <p>Thyroid disease</p>
        <p>Pregnancy</p>
        <p><em>Reduced dosage may be required for patient on MAO inhibitor as these older anti-depressant drugs cause epinephrine to increase its strength of action.</em></p>
        <p><strong>Adverse Effects</strong></p>
        <p>Tremor, anxiety, headache, cerebral hemorrhage, agitation, tachycardia, VF, palpitations, hypertension, angina pain, Pallor, diaphoresis (profuse sweating)</p>
        <p><strong>Actions</strong></p>
        <ul>
            <li>Sympathomimetic (producing physiological effects characteristic of the sympathetic nervous system by promoting the stimulation of sympathetic nerves)</li>
            <li>Interferes with release of histamines and other chemicals released in response to exposure to an allergen</li>
            <li>Increased heart rate, and strength of contraction and</li>
            <li>Increased ventricular irritability</li>
            <li>Vasoconstriction with elevation of blood pressure</li>
            <li>Bronchial dilation by relaxation of bronchial smooth muscle</li>
        </ul>
        <p><strong>Dosage and Administration (Delayed Protocol)</strong></p>
        <p>0.5 mg IM for anaphylaxis - repeat q 5 – 20 min</p>
        <p>Some patients may have a medic alert bracelet advising a higher dose (0.5mg) Some presentations may require a higher dose and the EPOS physician will guide you. Be prepared to provide a detailed description of the present state and trend of your patient and a history of the severity of previous exposures.</p>
        <p>Typically 0.01mg/KG to a max of 0.3mg</p>
        <p>An adult Epipen contains 0.3mg of Epinephrine (IM)</p>
        <p>You may be given an order for IM or SC injection method Preferred site is in the thigh (use shoulder as an alternative)</p>
        `,
    },
    {
        id: 'glucagon',
        sectionId: 'drugs',
        charts: [],
        title: 'Glucagon',
        lastUpdated: '6 Jan 2022',
        body: `
        <p><strong>RS Indications (DELAYED PROTOCOL)</strong></p>
        <p>Cases of hypoglycemia (BG &lt; 4.0mmol/L) where glucose administration has not been effective in raising blood sugar levels and patients LOC is still decreased.</p>
        <p><strong>Contraindications</strong></p>
        <p>Hypersensitivity to glucagon or its components</p>
        <p>Tumor involving the adrenal gland</p>
        <p><strong>Precautions</strong></p>
        <p>Hepatic (liver) or renal (kidney) insufficiency</p>
        <p>Insulinoma – may stimulate further release of insulin and precipitate/worsen Hypoglycemia&nbsp;</p>
        <p>Pregnancy / lactation</p>
        <p><strong>Adverse Effects</strong></p>
        <p>Nausea / vomiting Respiratory distress Hypokalemia ( Low Potassium) &nbsp;Urticaria</p>
        <p>Hypotension Possible transient increased HR and BP</p>
        <p><strong>Actions</strong></p>
        <p>Elevates glucose levels by accelerating the breakdown of glycogen to glucose in the liver</p>
        <p><strong>Pharmacology</strong></p>
        <p>Onset 8-10 min</p>
        <p>Peak 20-30 min</p>
        <p>Half-life 3-6 min</p>
        <p>Duration 19-32 min</p>
        <p><strong>Dosage and Administration</strong></p>
        <p>Supplied in powder form – requires reconstitution.</p>
        <p>The glucagon kit aboard will have 2 parts – a vial with a compressed white tablet and a syringe with fluid</p>
        <ol>
            <li>Insert the syringe and dispense contents into the vial</li>
            <li>Roll, DO NOT shake, until liquid is completely clear with NO particles and water-like consistency</li>
            <li>Withdraw dosage as prescribed by doctor order and administer (Lateral aspect of thigh or shoulder)</li>
        </ol>
        <p>Usual dosages are 0.5-1mg IM or SC</p>
        <ol>
            <li>After administration and patient LOC increases to a point where they are able to eat. Follow with carbohydrates</li>
            <li>Patient must go to hospital after Glucagon administration</li>
        </ol>
        `,
    },
    {
        id: 'glucose-gel',
        sectionId: 'drugs',
        charts: [],
        title: 'Glucose Gel',
        lastUpdated: '6 Jan 2022',
        body: `
        <p><strong>RS Indications</strong></p>
        <p>Patient with confirmed or suspected hypoglycemia (BG &lt; 4.0 mmol/L)</p>
        <p><strong>Contraindications</strong></p>
        <p>Do NOT administer to patient with decreased LOC who is positioned on their back. Patient must be ¾ prone for drainage.</p>
        <p><strong>Adverse Effects</strong></p>
        <p>May increase airway management problems. If so, discontinue use and call EPOS for orders for Glucagon.</p>
        <p><strong>Action</strong></p>
        <p>Increases blood sugar levels in the blood</p>
        <p><strong>Pharmacology</strong></p>
        <p>Onset Up to 10 minutes</p>
        <p>Peak &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;20-30 mins</p>
        <p>Half-life&nbsp;&nbsp;&nbsp;3-6 mins</p>
        <p>Duration&nbsp;&nbsp;19-32 mins</p>
        <p><strong>Dosage and Administration</strong></p>
        <p>1 tube is one dose (15g of glucose) May administer up to 2 doses Administer orally</p>
        <p>In cases of patient with decreased LOC, ensure patient is positioned ¾ prone in recovery position and administer to patient in small amounts, using a tongue depressor, to the buccal pouch</p>
        <p>Once patient LOC improves and is able to swallow – follow with carbohydrates.</p>
        <p>If patients BG still remains &lt; 4.0mmol/L after 2 tubes, contact EPOS for order for Glucagon.</p>
        `,
    },
    {
        id: 'naloxone',
        sectionId: 'drugs',
        charts: [],
        title: 'Naloxone (Narcan)',
        lastUpdated: '6 Jan 2022',
        body: `
        <p><strong>RS Indications</strong></p>
        <p>Indicated for patients with suspected Narcotic Overdose who meet the protocol</p>
        <p><strong>Contraindications</strong></p>
        <p>Allergy or known hypersensitivity to naloxone</p>
        <p><strong>Precautions</strong></p>
        <p>Be prepared for patient combativeness</p>
        <p>In the chronic narcotic abuser, may precipitate withdrawal symptoms Miscarriage or premature labour</p>
        <p>Very short half-life; monitor patient closely and prepare to re-dose if deterioration occurs</p>
        <p><strong>Adverse Effects</strong></p>
        <p>Combativeness/</p>
        <p>Withdrawal signs and symptoms Miscarriage or premature labour Hypotension or hypertension Nausea / vomiting</p>
        <p>Diaphoresis</p>
        <p><strong>Action</strong></p>
        <p>Opioid/narcotic antagonist</p>
        <p>Reverses the effects of the opioid (respiratory depression, sedation, hypotension) Competes for the same receptor site as the opioid thereby removing the opioid from the receptor (taking away the effects).</p>
        <p><strong>Pharmacology</strong></p>
        <p>Onset 1 minute</p>
        <p>Peak Unknown</p>
        <p>Half-life 1-3hours Duration 45 minutes</p>
        <p><strong>Dosage and Administration</strong></p>
        <p>As per protocol 4.1 0.8mg IM</p>
        <p>Administer to lateral thigh or shoulder Max 2 doses for RS</p>
        <p>Very short half-life; monitor patient closely and prepare to re-administer if deterioration occurs</p>
        `,
    },
    {
        id: 'nitroglycerin',
        sectionId: 'drugs',
        charts: [],
        title: 'Nitroglycerin',
        lastUpdated: '6 Jan 2022',
        body: `
        <p><strong>RS Indications</strong></p>
        <p>Chest pain suggestive of cardiac ASC (including atypical presentations)</p>
        <p><strong>Contraindications</strong></p>
        <p>Patient not prescribed Nitroglycerin Nitro is expired.</p>
        <p>BP ≤ 100 or</p>
        <p>Heart rate &lt; 50bmp (taken by palpation)</p>
        <p>Erectile dysfunction drugs</p>
        <p>(Viagra or Levitra in the past 24 hrs or Cialis in the past 48 hrs).</p>
        <p>Entonox in past 5 mins</p>
        <p><strong>Precautions</strong></p>
        <p>In right sided MI or inferior wall MI, administration of Nitro may decrease preload to dangerous levels and cause decreased LOC or cardiac arrest.</p>
        <p>Open or closed angle glaucoma</p>
        <p>Use with caution in the presence of hepatic or renal insufficiency. Patients on concurrent anti-hypertensive therapy.</p>
        <p><strong>Adverse Effects</strong></p>
        <p>Hypotension - Do not administer if blood pressure is &lt; 90 systolic or patient exhibits signs of significant hypo perfusion, headache, nausea or vomiting. Dizziness or fainting, weakness, headache, nausea, vomiting</p>
        <p>Tachycardia or bradycardia Flushed face, burning of tongue</p>
        <p><strong>Action</strong></p>
        <p>Relaxes vascular smooth muscle and produces coronary arterial vasodilation. Decreases preload, decreases MVO2 (myocardial oxygen consumption).</p>
        <p><strong>Pharmacology</strong></p>
        <p>Onset - 1 to 3 minute</p>
        <p>Peak - Unknown</p>
        <p>Half-life - 1 to 4 minutes</p>
        <p>Duration - 30 minutes</p>
        <p><strong>Dosage and Administration</strong></p>
        <p>1. mg spray SL - repeat q 3 min. - maintaining B/P &gt; 90 systolic</p>
        `,
    },
    {
        id: 'salbutamol',
        sectionId: 'drugs',
        charts: [],
        title: 'Salbutamol (Ventolin)',
        lastUpdated: '6 Jan 2022',
        body: `
        <p><strong>RS Indications</strong></p>
        <p>Shortness of breath with a history of asthma or COPD and carries own medication (Metered Dose Inhaler (MDI) – I.e. Salbutamol/Ventolin).</p>
        <p>In cases of allergic reaction where patient feels that their Ventolin may help with their respiratory distress. Only after the Allergic Reaction protocol 2.3 has been</p>
        <p>&nbsp;applied. (Refer to protocol 2.3 Anaphylaxis Notes and 2.2 Respiratory distress)</p>
        <p><strong>Contraindications</strong></p>
        <p>Patient not prescribed Salbutamol (Ventolin).</p>
        <p><strong>Adverse Effects</strong></p>
        <p>Palpitations</p>
        <p>Tachycardia at higher doses Nervousness / restless Headache</p>
        <p>Dizziness Tremor Coughing Nausea</p>
        <p>Pallor, flushed, or sweating</p>
        <p><strong>Action</strong></p>
        <p>Bronchodilator</p>
        <p>Sympathomimetic (producing physiological effects characteristic of the sympathetic nervous system by promoting the stimulation of sympathetic nerves)</p>
        <p><strong>Pharmacology</strong></p>
        <p>Onset 3-5 mins</p>
        <p>Peak 15-20mins</p>
        <p>Half-life 2.7 – 5 hours</p>
        <p>Duration&nbsp;&nbsp;4-6 hours</p>
        <p><strong>Dosage and Administration</strong></p>
        <p>Patient puffers administer either 100 microgram or 200 microgram per puff. Shake vigorously to load dose into the device</p>
        <p>Administered through inhalation – RS should be using a spacer to administer, but if not available immediately then administer without. Follow protocol for procedure.</p>
        `,
    },
]