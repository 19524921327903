import React from 'react'
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary'
import HealingIcon from '@material-ui/icons/Healing'
import LocalHospitalIcon from '@material-ui/icons/LocalHospital'
import AccessibilityIcon from '@material-ui/icons/Accessibility'

import assessmentImg from '../images/photos/boats.jpg'
import protocolsImg from '../images/photos/heli-diver.jpg'
import drugsImg from '../images/photos/mkcay.jpg'
import appendixImg from '../images/photos/rippin.jpg'


export interface Section {
  name: string
  path: string
  id: string
  icon: JSX.Element
  lastUpdated: string
  headerImageSrc: string
}

export const SECTIONS: Section[] = [
  {
    name: 'Assessment',
    path: '/assessment',
    id: 'assessment',
    icon: <AccessibilityIcon />,
    headerImageSrc: assessmentImg,
    lastUpdated: '6 Jan 2022',
  },
  {
    name: 'Protocols',
    path: '/protocols',
    id: 'protocols',
    icon: <LocalHospitalIcon />,
    headerImageSrc: protocolsImg,
    lastUpdated: '6 Jan 2022',
  },
  {
    name: 'Drugs',
    path: '/drugs',
    id: 'drugs',
    icon: <HealingIcon />,
    headerImageSrc: drugsImg,
    lastUpdated: '6 Jan 2022',
  },
  {
    name: 'Appendix',
    path: '/appendix',
    id: 'appendix',
    icon: <LocalLibraryIcon />,
    headerImageSrc: appendixImg,
    lastUpdated: '6 Jan 2022',
  },

]
  

export const getSection = (sectionId: string) => {
  return SECTIONS.find((value) => (
      value.id === sectionId
  ))
}