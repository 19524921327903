import React, { useState, useEffect } from 'react'
import { Typography, Dialog, DialogTitle, DialogContent, DialogContentText, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { ReactComponent as IosShare } from '../images/add-to-home.svg'

const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase()
    return /iphone|ipad|ipod/.test( userAgent ) && !window.MSStream
}

const isInStandaloneMode = () => (window.navigator as any).standalone

export const InstallPrompt = () => {
    const [ showInstallMessage, setShowInstallMessage ] = useState(true)

    useEffect(() => {
        setShowInstallMessage(isIos() && !isInStandaloneMode())
    }, [])

    if (!showInstallMessage) {
        return <></>
    }

    const onClose = () => {
        setShowInstallMessage(false)
    }

    return (
        <Dialog
            open={true}
            onClose={onClose}
        >
            <DialogTitle id="alert-dialog-title" style={{marginTop: '25px'}}>
                This website works offline
                <IconButton aria-label="close" onClick={onClose} style={{position: 'absolute', right: 0, top: 0}}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                <Typography>
                    Install this app on your phone -
                    <br />
                    <ol>
                        <li>Tap <IosShare /></li>
                        <li>Tap "Add to Home Screen"</li>
                    </ol>
                </Typography>
            </DialogContentText>
            </DialogContent>
        </Dialog>
    )
}