import React from 'react'
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Typography } from '@material-ui/core'


export const generateRow = (...rows: string[]) => {
    const elements = rows.map((el) => (
        <TableCell>{el}</TableCell>
    ))
    return (
        <TableRow>
            {elements}
        </TableRow>
    )
}


interface TableSpec {
    title?: string
    header: JSX.Element
    rows: JSX.Element[]
}
export const generateTable = (table: TableSpec) => (
    <>
        {table.title && (
            <Typography variant="h6" gutterBottom={true} style={{marginTop: '20px'}}>
                {table.title}
            </Typography>
        )}
        <TableContainer>
            <Table>
                <TableHead>
                    {table.header}
                </TableHead>
                <TableBody>
                    {table.rows}
                </TableBody>
            </Table>
        </TableContainer>
    </>
)