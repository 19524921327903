import React from 'react'
import { Grid, Slide, Card, CardContent, Typography, ListItem, ListItemText, List, CardMedia, Divider, useTheme } from '@material-ui/core'
import { Link } from 'react-router-dom'

import { DateString } from './util'
import { getTopics } from '../data/topics'
import { Section as SectionModel } from '../data/sections'

export const Section: React.FC<{ section: SectionModel }> = (props) => {
    const topics = getTopics(props.section.id) || []
    const theme = useTheme()
    const list = topics.map((topic) => (
        <Link key={topic.id} to={`${props.section.path}/${topic.id}`} style={{ textDecoration: 'none', color: theme.palette.text.primary}}>
            <ListItem button>
                <ListItemText
                    primary={topic.title}
                />
            </ListItem>
        </Link>
    ))
    
    return (
        <Grid container spacing={4}>
            <Grid item xs={12} style={{ marginTop: '30px' }}>
            <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                <Typography
                align={'center'}
                gutterBottom={true}
                variant={'h1'}
                >
                 {props.section.name}
                </Typography>
            </Slide>
        </Grid>
            <Grid item xs={12}>
                <Slide direction={'up'} in={true} timeout={400}>
                    <Card>
                        <CardMedia
                            image={props.section.headerImageSrc}
                            title={props.section.name}
                            component='img'
                            style={{
                                width: '100%',
                                height: 'auto',
                                maxHeight: '500px',
                                margin: 'auto',
                            }}
                        />
                        <CardContent>
                            <DateString date={props.section.lastUpdated} />
                            <Divider/>
                            <List>
                                {list}
                            </List>
                        </CardContent>
                    </Card>
                </Slide>
            </Grid>
        </Grid>
    )
}