import { Grid, Grow, Fade, Card, CardContent, Typography, Divider } from '@material-ui/core'
import React from 'react'
import { match } from 'react-router-dom'

import { DateString } from './util'
import { getTopic } from '../data/topics'

export const Topic: React.FC<{match: match, sectionId: string}> = (props) => {
    const match: any = props.match.params
    const topic = getTopic(props.sectionId, match.topicId)
    if (!topic) {
        return (
            <div>404 Not Found</div>
        )
    }

    const charts = topic.charts.map((chart, idx) => (
        <span key={idx}>
            {chart}
            <Divider />
        </span>
    ))

    return (
        <Fade in={true}>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Card elevation={0}>
                        <CardContent>
                            <Typography gutterBottom={true} component={'h1'} variant={'h5'}>
                                {topic.title}
                            </Typography>
                            <DateString date={topic.lastUpdated} />
                            <Divider />

                            {topic.markup}
                            <Typography gutterBottom={true} component={'div'} variant={'body2'}>
                                <div dangerouslySetInnerHTML={{ __html: topic.body}}></div>
                            </Typography>
                            
                        </CardContent>
                    </Card>
                </Grid>
                {!!charts.length && (
                <Grid item xs={12}>
                    <Grow in={true}>
                        <Card>
                            <CardContent>
                                <Typography gutterBottom={true} component={'h1'} variant={'h5'}>
                                    {topic.title} - Resources
                                </Typography>
                                <Divider />
                                {charts}
                            </CardContent>
                        </Card>
                    </Grow>
                </Grid>
                )}
            </Grid>
        </Fade>
    )
}