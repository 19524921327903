import { Topic } from '.'

export const communications: Topic[] = [
    {
        id: 'procedures',
        sectionId: 'appendix',
        charts: [],
        title: 'Procedures',
        lastUpdated: '6 Jan 2022',
        body: `
        <h3><strong>Contacting EPOS</strong></h3>
        <p><strong>Calls to EPOS Physicians are to be done through JRCC.</strong></p>
        <p>If medical consult (advise or orders) is required during a SAR incident, or if a medical evacuation may be required, ensure that JRCC is advised as these arrangements and medical recommendations on transport happen are directed through EPOS.</p>
        <p><strong>Communicating Patient Information (Radio or Phone Notification Format)</strong></p>
        <p>Communication of information may take place via radio or phone, by Patient Care Record, or person to person at patient handover.</p>
        <p>Notification regarding patient status will normally be passed through MCTS or JRCC.</p>

        <p>Have information such as completed PCR with vital signs and detailed history at hand. If medications may are be ordered from Ship’s Pharmacy, have copy of CGFLS</p>
        <p>400.00.07 ready for reference (Annex A - Scale of Acquisition).</p>

        <p><strong>Use the SBAR </strong>format</p>
        <p><strong>S&nbsp; </strong>- <strong>Situation	</strong>- Introduce yourself</p>
        <ul>
            <li>Clear and brief description/overview of current situation</li>
            <li>Include position of ship / Weather conditions / time to closest next level of care</li>
        </ul>
        <p><strong>B&nbsp; - Background	</strong>- Briefly state the pertinent history.</p>
        <ul>
            <li>What got us to this point?</li>
            <li>Include patients age and sex.</li>
        </ul>
        <p><strong>A&nbsp; - Assessment	</strong>- Summarize the facts and give your assessment</p>
        <ul>
            <li>What have you done? What was the patient’s response?</li>
            <li>What is are patients current vital signs, status and trend?</li>
        </ul>
        <p><strong>R - Recommendation</strong></p>
        <p>- What recommendation are your asking for (i.e. advise, orders, transport recommendation)?</p>
        <p><strong>Write down and read order back to physician to ensure accuracy; note time, physician’s name and license number or facility</strong></p>
        `,
    },
    {
        id: 'phone-numbers',
        sectionId: 'appendix',
        charts: [],
        title: 'Phone Numbers',
        lastUpdated: '6 Jan 2022',
        body: `
        <p><strong>JRCC:</strong></p>
        <ul>
            <li>1-800-567-5111 (Public or SAR Resource)</li>
        </ul>
        <p><strong>EPOS (Emergency Physician Online Support)</strong></p>
        <ul>
            <li>Contact RCC and the controller will conference in EPOS.</li>
            <li>Contact through RCC ensures all parties are aware of the patient’s condition and enables more effective transport decisions.</li>
        </ul>
        <p><strong>Poison Control Centre: (24 hours): </strong>604-682-5050 / 1-800-567-8911</p>
        <p><strong>CANUTEC – Dangerous Goods Emergency: (24 hours) </strong>1-613-996-6666</p>
        <p><strong>Vancouver General Hospital Hyperbaric Chamber:</strong></p>
        <ul>
            <li>direct to chamber 604-875-4033</li>
            <li>24 hours VG Hospital 604-875-5000</li>
        </ul>
        <p><strong>DFO Shellfish Area Closures:</strong> 1-866-431-3474 (24hrs recorded)</p>
        <p><strong>BC Nurse Health Line - </strong>General Health Info (RN staff 24 hours): 1-866-215-4700</p>
        <p><strong>CCG Base Victoria</strong>, Switchboard: 250-480-2600</p>

        <p><strong>CCG Operations Centre (ROC)</strong>: 250-413-2800</p>
        <p><strong>Rescue Specialist Co-ordinator, Victoria:</strong> 250-480-2635</p>
        <p><strong>Cardiac Science – Technical Support ( 24 hours): </strong>1-888-466-8686</p>
        <p><strong>Product Distribution Centre: </strong>604-660-0500</p>
        <p><strong>Health Canada Victoria - Workplace Health and Public Safety: </strong>250-363- 3566/3275</p>
        `,
    },
]