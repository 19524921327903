import React from 'react'

import { Topic } from '.'
import { ReactComponent as Cardiac1 } from './resources/2.1.1.svg'
import { ReactComponent as Cardiac2 } from './resources/2.1.2.svg'
import { ReactComponent as Cardiac3 } from './resources/2.1.3.svg'
import { ReactComponent as Cardiac4 } from './resources/2.2.1.svg'
import strokeDiagram from './resources/2.3.1.jpg'
import { generateTable, generateRow } from '../util'
import { Divider } from '@material-ui/core'

export const cardiac: Topic[] = [
    {
        id: 'aed-cardiac-arrest',
        sectionId: 'assessment',
        charts: [
        ],
        title: 'AED Cardiac Arrest',
        lastUpdated: '6 Jan 2022',
        body: '',
        markup: <>
            <Cardiac1 />
            
            <Divider />

            <Cardiac2 />

            <Divider />

            <Cardiac3 />
            <p><strong>Indications for “AED Cardiac Arrest”</strong></p>
            <ul>
                <li>Patient has absent carotid pulseless.</li>
                <li>AED is attached any time CPR would be performed</li>
            </ul>
            <p><strong>Contraindications</strong></p>
            <ul>
                <li>AED is not applied to a patient that has a pulse.</li>
                <li>In Traumatic Arrest – If the AED will significantly impede extrication of the patient to higher level of care.</li>
                <li>No CPR form&nbsp;(visualized by RS)&nbsp;*Must be signed by patient and doctor*</li>
            </ul>
            <p><strong>Critical History</strong></p>
            <ul>
                <li>When did patient go into cardiac arrest?</li>
                <li>How did they go into cardiac arrest? (I.e. – was this a traumatic, hypothermic or medical arrest)</li>
                <li>Was CPR performed from time of arrest to rescuer arrival?</li>
                <li>Is there any medical history available on the patient?</li>
                <li>Submersed – attempt to confirm time of submersion.</li>
                <li>Immersed in water or snow – confirm time of immersion.</li>
                <li>When considering if patient will be candidate for ECMO - Extra corporeal membrane oxygenation) consider:</li>
                <li>It is important is to determine if the patient arrested prior to being cooled (not a candidate for ECMO) or if patient was cooled then arrested (is a candidate for ECMO).</li>
                <li>I.e. A search that ends in patient a being found clinging to a boat but slips into the water as the SRU arrives on scene – in this case the patient may be submersed when you rescue them but it is clear that the patient was likely hypothermic first</li>
            </ul>
            <p><strong>Key features</strong></p>
            <p>Patient pulseless with no spontaneous respirations or movement Pale in colour or cyanotic.</p>
            <p><strong>Physical Assessment</strong></p>
            <ul>
                <li>In Primary - If traumatic, use jaw thrust to open airway. OPA and suction.</li>
                <li>Assess for signs of breathing and circulation at the same time. Check carotid pulse for 10 seconds. Confirm pulseless – if hypothermic check pulse for 30secs.</li>
                <li>Patient assessment should be continued during CPR if number of rescuers allows. Finish off the primary and continue to secondary survey.</li>
                <li>If traumatic – critical interventions still apply (ie direct pressure/tourniquets for bleeds, cover open chest wounds with gauze). CPR should not be interrupted to complete this task. Delegate.</li>
            </ul>

            {generateTable({
                header: generateRow('Prerequisite', 'Actions'),
                rows: [
                    generateRow('New born (0 - 1 year old)'),
                    generateRow('Pulse Rate < 100', 'Assist ventilation with O2. 1/2 sec on patients respiration. Reassess pulse after 2 mins'),
                    generateRow('Pulse Rate < 60 and Signs of Poor Perfusion', 'Start CPR. Reassess after 2 mins'),
                    generateRow('Infants and Children'),
                    generateRow('Inadequate breathing and Pulse Rate > 60 bpm', 'Assist ventilation with O2. 1/3 sec on patients respiration. Reassess pulse after 2 mins'),
                    generateRow('Inadequate breathing and Pulse rate > 60 bpm and Signs of poor perfusion (shock)', 'Start CPR. Reassess after 2 mins'),
                    generateRow('Pulse rate < 60 bpm and Signs of poor perfusion', 'Start CPR. Reassess after 2 mins'),
                ]
            })}

            <p><strong>Considerations</strong></p>
            <ul>
                <li>Use a blanket or stretcher to <strong>isolate the patient from conductive surfaces</strong></li>
                <li><strong>If chest is hairy, shave </strong>to ensure electrode contact. <strong>Ensure chest is dry.</strong></li>
                <li>If medication patches are present on chest, remove these prior to defibrillation.</li>
                <li>If implanted pacemaker or other device present place pads 1”clear of device.</li>
                <li>If pregnant, follow the standard protocol but raise her right side 30 degrees</li>
                <li><strong>For small children - </strong>ensure 1” between pads. If not place one on front of chest and one on the back.</li>
                <li>Compressions must be stopped during AED analyze phase, but ensure that compressions are being done during AED charging.</li>
                <li>Prior to pushing button for shock ensure all are clear of patient stating: “On 3 we’re going to shock. 1 - I’m clear, 2 - you’re clear, 3 - we’re all clear”.</li>
                <li>AED will advise shock or no shock. Once this is completed resume CPR if there are no signs of life. After 5 analyze phases (approximately 10mins) check for a pulse – if no pulse continue CPR. Contact EPOS at 15 mins Continue to check pulse at every 5 analyze phases.</li>
                <li>Rotate compressors every 2 mins (or every analyze cycle).</li>
            </ul>

            {generateTable({
                title: 'CPR – Compression/ventilation ratio, depths and rates.',
                header: generateRow('COMPRESSION RATIO (COMPRESSIONS : VENTILATIONS)', 'Depth', 'Rate'),
                rows: [
                    generateRow('Adult', '1 or 2 rescuers	30:2', '2-2.5” (5-6cm)', '100-120 /min. 110 is ideal'),
                    generateRow('Child/Infant', '1 rescuer	30:2', '1/3 to 1/2 the chest', '100-120 /min. 110 is ideal'),
                    generateRow('Child/Infant', '2 rescuers	30:2', '1/3 to 1/2 the chest', '100-120 /min. 110 is ideal'),
                ]
            })}

            <p><strong>Compressions</strong></p>
            <p>Ensure recoil of the chest in between all compressions to allow proper filling and compression of the heart.</p>
            <p><strong>Ventilations</strong></p>
            <p>Vents should be about 1 second long – just until the chest rises. Venting too fast or over ventilating will cause ventilations to compromise survival.</p>
            <p>Do not interrupt compressions for &gt;10sec to ventilate.</p>

            <p><strong>ECMO – Extra Corporeal Membrane Oxygenation</strong></p>
            <p>ECMO is the process of invasive cannulation of central vascular system to allow oxygenation, removal of carbon dioxide, control of temperature, and cardiovascular pressure and blood flow support. Patients that have suffered severe hypothermia leading to cardiac arrest have had remarkably good outcomes even with prolonged CPR. During this process the rewarming of the patient can be controlled allowing the cooled organs to recover.</p>
            <p>Note that the patient does not have to be in cardiac arrest to benefit from this procedure, a severely hypothermic patient who still has pulses may also fit. This procedure is invasive and ties up many pre-hospital and hospital resources.</p>
            <p>Therefore, the BCEHS EPOS physician must accurately assess whether the situation and patient condition warrants that utilization.</p>
            <p><strong>After Use</strong></p>
            <p>Following any use of AED (shock or no shock), download data from the AED and send to RS Coordinator who will forward to AED Medical Director.</p>
        </>
    },
    {
        id: 'chest-pain-protocol',
        sectionId: 'assessment',
        charts: [],
        title: 'Chest Pain Protocol',
        lastUpdated: '6 Jan 2022',
        body: ``,
        markup: <>
        
        <Cardiac4 />

        <p><strong>NOTES:</strong></p>
        <ul>
            <li>Outcome is better if there is no delay in the patient reaching hospital.</li>
            <li>Nitro must be patients own or it is a delayed protocol.</li>
            <li>It does not matter if the patient has already had Nitro prior to your arrival. The RS will still initiate this protocol.</li>
            <li>Do not shake Nitro bottle. Prime with 2 pumps to deck or ground.</li>
            <li>If this is 1<sup>st</sup> time onset and patient is not prescribed nitro then you can administer ASA provided no contraindications for ASA present.</li>
            <li>In order to administer Nitro from ships pharmacy you must have a Dr. order.</li>
            <li>If patient becomes pain free – still transport, or confirm with EPOS if patient refuses transport.</li>
        </ul>
        <p><strong>Indications</strong></p>
        <ul>
            <li>Chest pain that is suggestive of cardiac (MI or angina) with a history and prescription for Nitroglycerin.</li>
        </ul>
        <p>OR</p>
        <ul>
            <li>1<sup>st</sup> episode of chest pain suggestive of cardiac chest pain.</li>
        </ul>
        <p><strong>Contraindications</strong></p>
        <p><strong>Nitroglycerin</strong></p>
        <ul>
            <li>Patient not prescribed Nitroglycerine.</li>
            <li>Nitro is expired.</li>
            <li>BP ≤ 100</li>
            <li>Heart rate &lt; 50bmp</li>
            <li>Erectile dysfunction drugs (Viagra or Levitra in the past 24 hrs or Cialis in the past 48 hrs).</li>
            <li>Entonox in past 5 mins.</li>
        </ul>
        <p><strong>ASA</strong></p>
        <ul>
            <li>Allergy to ASA</li>
            <li>Active gastrointestinal bleed or active bleeding</li>
            <li>Pediatric patients with flu like symptoms (Rye’s Syndrome)</li>
            <li>Patient has already taken daily dose (RS may top up to total of 160mg)</li>
        </ul>
        <p><strong>Critical History</strong></p>
        <ul>
            <li>SAMPLE and OPQRST first</li>
            <li>To determine if the patient has the key features of chest pain that is suggestive of cardiac.</li>
            <li>To determine if contraindications are met.</li>
            <li>Can you describe the pain and what it feels like?</li>
            <li>Does the pain radiate?</li>
            <li>If you cough or take a deep breath does that change the pain?</li>
            <li>When did the pain or discomfort start and what were you doing at the time?</li>
            <li>Have you had this before?</li>
            <li>If you are prescribed Nitro – would you normally take your Nitro for this? If not – why?</li>
            <li>Why did you call us today? (patients that carry Nitro for chest pain self-medicate for pain – so what makes today different – is the pain unrelieved /more intense / different?</li>
            <li>Does the patient have any medical history pertaining to hypertension, diabetes, previous heart attack or angina, or any lung disease?</li>
        </ul>
        <p><strong>Key Features</strong></p>
        <ul>
            <li>Weak, anxious, confused.</li>
            <li>Pale, cool, diaphoretic.</li>
            <li>Sub sternal chest pain. Usually described as a pressure, squeezing on the chest, may feel like indigestion.</li>
            <li>Patient may be short of breath (SOB) and may want to sit up.</li>
            <li>Auscultation may sound clear or have signs of pulmonary edema, depending on the nature of infarction.</li>
            <li>Tachycardia or bradycardia.</li>
            <li>Nausea / vomiting.</li>
        </ul>
        <p><strong>Physical Assessment</strong></p>
        <ul>
            <li>Primary – loosen clothing, expose and examine chest to look for scars, patches, etc. Decrease anxiety. Position of comfort if not hypotensive.</li>
            <li>Auscultation.</li>
            <li>Assessment for peripheral edema in hands neck and ankles.</li>
            <li>Check for scars to chest which may indicate previous surgeries or pacemaker.</li>
            <li>Have patient sit ideally on your stretcher when administering Nitro as it may drop their blood pressure and cause them to become dizzy.</li>
            <li>If pt BP drops below 100mmHg or HR below 50bpm</li>
            <li>Discontinue Nitro use</li>
            <li>Lower patient so they are supine</li>
            <li>Consider raising legs</li>
            <li>Reassess ABC and vitals</li>
        </ul>
        <p><strong>Considerations</strong></p>
        <ul>
            <li>Cardiac chest pain is considered treatable with Nitro at any pain level. Even if the pain has subsided to a 1/10 you may still administer Nitro provided the BP remains &gt; 100mmHg and HR is NOT &lt; 50 bpm.</li>
            <li>If patient goes into cardiac arrest at any time start CPR and apply the AED immediately. Follow AED protocol.</li>
            <li>Consider that there are other causes of chest pain:</li>
            <li>Pulmonary embolism</li>
            <li>Aortic aneurysm</li>
            <li>Pericarditis</li>
            <li>Spontaneous pneumothorax</li>
            <li>Gastric ulcer</li>
            <li>Gallbladder attack (cholecystitis)</li>
        </ul>

        {generateTable({
            header: generateRow('CARDIAC CHEST PAIN', 'CONGESTIVE HEART FAILURE (CHF)', 'PULMONARY EMBOLISM (PE)'),
            rows: [
                generateRow('May be weak, anxious, confused, desire to sit up, tachycardia, shocky (pale cool diaphoretic), cyanosis'),
                generateRow('Sub sternal chest pain/pressure. Could be brought on at rest (higher risk) or during exertion.', 'If only CHF, no pain. Can be caused by ACS and may be associated with pain.', 'Sudden onset focal chest pain'),
                generateRow('', 'Productive cough may be pink in color', 'Productive cough may be pink in color'),
                generateRow('Pain does not increase with cough or deep breath', '', 'Pain usually increases with inspiration and or expiration'),
                generateRow('Pain may radiate to jaw, shoulder, arms, abdomen or back.', '', 'Typically non radiating.'),
                generateRow('', 'JVD', 'JVD'),
                generateRow('', 'Edema of dependent (lower) parts.', ''),
                generateRow('', 'JVD', 'JVD'),
                generateRow('Indigestion/nausea/vomiting'),
                generateRow('Treat as per Chest Pain Protocol', 'Treat with O2 if in respiratory distress, SPO2<95%, or shock. Positive pressure ventilation as required.', 'Treat with O2 if in respiratory distress, SPO2<95%, or shock. Positive pressure ventilation as required.'),
                
            ]
        })}
        </>
    },
    {
        id: 'stroke',
        sectionId: 'assessment',
        charts: [],
        title: 'Stroke (CVA- Cerebrovascular Accident)',
        lastUpdated: '6 Jan 2022',
        body: ``,
        markup: <>
        <div style={{ maxWidth: '600px', margin: '30px auto' }}>
            <img src={strokeDiagram} alt='stroke diagram' style={{ width: '100%' }}/>
        </div>

        <h2><strong>Notes:</strong></h2>
        <ol>
            <li>Ensure proper airway maintenance.</li>
            <li>TIME – hot stroke is a stroke with major symptom that can be treated 6 hrs from time of onset to hospital with signs and symptoms with in the FAST key features) * Can be up to 10 hours but with diminishing results</li>
            <li>Ensure other causes of symptoms are ruled out. (AEIOU TIPS)</li>
            <li>If transport is &gt;30 mins, rotate patient position.</li>
            <li>Continued assessment of GCS is important to determine&nbsp; trend.</li>
        </ol>
        <h2><strong>Indications for HOT STROKE CRITERIA</strong></h2>
        <ul>
            <li>Patient has signs and symptoms of stroke as outlined in the FAST assessment model (see Key Features section).</li>
            <li>Patients’ symptom onset was less than 6 hours ago. (can be up to 10 hrs)</li>
        </ul>
        <h2><strong>HOT STROKE - Transport Decision</strong></h2>
        <ul>
            <li>Notify RCC that patient meets criteria.</li>
            <li>RCC should then notify EHS dispatch to see if the patient meets the auto launch criteria for the province.</li>
            <li>Continue transport to original facility unless you hear back from RCC that the transport for the patient has changed.</li>
        </ul>
        <h2><strong>Critical History</strong></h2>
        <ul>
            <li>High return questions and physical exam to confirm that stroke is suspected.</li>
            <li>When did the symptoms start? This is important to determine if the patient fits into the Hot Stroke Criteria for the receiving facility (must be no more than 6 hrs between onset of symptoms and arrival at the facility in order to work effectively).</li>
            <li>Was the onset sudden or gradual?</li>
            <li>Did the patient wake with symptoms or did the onset happen&nbsp;after waking?</li>
            <li>Does the patient have a past history of stroke/CVA or TIA (transient ischemic Attack)?</li>
            <li>Are there any deficits left from the previous stroke? This will help you determine what the new deficits are as a result of this episode.</li>
            <li>Have the symptoms worsened/improved/or stayed the same? What is the patients’ trend and over what time period? Was there a seizure? (not common with onset of stroke but may alert you to watch for additional seizure activity)</li>
        </ul>
        <h2><strong>Key Features HOT STROKE</strong></h2>
        <ul>
            <li><strong>Facial droop </strong>– one side of the face doesn’t move as well as the other. Weakness, paralysis or loss of sensation of the face, arms, or legs, often on only one side of the body.</li>
            <li><strong>Arm drift </strong>– have the casualty hold both arms out; one arm may not move or drifts down compared to the other. May also have weakness or paralysis to one side of the body.</li>
            <li><strong>Speech </strong>– the casualty slurs words; uses incorrect words or is unable to speak or understand speech.</li>
            <li><strong>Time </strong>– the time of onset of symptoms (if looking for Hot Stroke patient must have had onset less than 6hrs ago)</li>
        </ul>
        <h2>OTHER SIGNS AND SYMPTOMS</h2>
        <ul>
            <li>Loss of vision or visual disturbances.</li>
            <li>Unexplained dizziness or unsteadiness.</li>
            <li>Confusion or change in personality or a change in the level of mental ability including the ability to concentrate</li>
            <li>Severe headache.</li>
            <li>Decreased consciousness, seizures or convulsions.</li>
            <li>Rapid and strong pulse.</li>
            <li>Respiratory distress.</li>
            <li>Pupils unequal in size or reaction.</li>
            <li>Loss of bladder or bowel control; or nausea and vomiting.</li>
            <li>Hypoglycemia can present with signs and symptoms of a stroke.</li>
        </ul>
        <h2><strong>Physical Assessment</strong></h2>
        <p><strong> F </strong>– Have the patient smile or show teeth. Look for the same number of teeth on each side and/or looking to see if one side doesn’t move as well as the other or droops.</p>
        <p> <strong>A </strong>- Have the patient close their eyes and hold their arms straight out palm up for about 10 secs. Looking to see if one arm drifts down more than the other.</p>
        <p> <strong>S </strong>- Have the patient say “You can’t teach a dog new tricks” or some simple familiar saying. Looking for slurring, wrong words or inability to speak.</p>
        <p> <strong>T </strong>- When did the patient last look/respond normally.</p>
        <ul>
            <li>Oxygen should only be administered if SPO2 is &lt;95% and or presents with obvious signs of shock (pale, cool, clammy skin, rapid pulse) or respiratory distress.</li>
            <li>Patient position (treat gently especially weak or paralysed parts)</li>
            <li>Decreased LOC - should be lateral, unaffected side down</li>
            <li>Conscious - position supine with head and shoulders <em>slightly </em>raised and in a neutral position. (alignment at midline to assist jugular venous return)</li>
            <li>If the patient fits the Hot Stroke Protocol the method of transport and the destination will depend on availability of provincial resources.</li>
            <li>Call RCC to ensure best patient outcome.</li>
            <li>If patient transport is greater than 30 mins ensure to rotate patient to prevent ulcers/bed sores from forming.</li>
            <li>Ongoing GCS and neurological assessments to establish and confirm trend.</li>
            <li>Do not assist suspected stoke with medications.</li>
        </ul>
        <h2><strong>Considerations</strong></h2>
        <p><strong>Cerebrovascular Accident (CVA) / Stroke </strong>– A non-traumatic brain injury caused by interruption of blood flow to an area of the brain. <strong>May be occlusive </strong>(blood clot has blocked blood flow to an area of the brain) <strong>or hemorrhagic in nature </strong>(ruptured or leaky blood vessel has interrupted blood flow to the brain). Presentation may vary depending on area of brain and degree of damage.</p>
        <p><strong>Transient Ischemic Attack (TIA) </strong>– A temporary event with stroke-like symptoms, usually abrupt in onset, lasting anywhere from a few minutes to 24 hours. Assess and treat as per CVA. May be a warning sign that a more serious event (CVA) may follow. Half of all CVA’s occur within a few days of a TIA.</p>
        <p>Timely recognition, transport and notification are extremely important in stroke. If the patient is having a thrombolytic/occlusive stroke they can be treated and recover with little to no deficits if a thrombolytic drug (tPA) is given. Best outcomes are seen if strokes are treated within 4.5hrs of onset, but strokes can now be treated successfully up to 10hrs.</p>
        <p><strong>Also consider other reasons for decreased LOC/altered mental status and the use AEIOU TIPS tool to assess (see assessment section)</strong></p>

        </>,
    },
]