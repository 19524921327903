import { Topic } from '.'

export const transport: Topic[] = [
    {
        id: 'transport',
        sectionId: 'appendix',
        charts: [],
        title: 'Transport - BC EHS Auto Launch Criteria & Preferred Destinations',
        lastUpdated: '6 Jan 2022',
        body: (`
        <p><strong>BC EHS Auto Launch Criteria.</strong></p>
        <p>Auto launch is a protocol that BC EHS Dispatch has in place to ensure a rapid response to certain incidents or patient conditions. The response in these categories is generally by helicopter and is based on a combination of mechanism of injury as well as patient condition.</p>
        <p><strong>LZ</strong></p>
        <ul>
            <li>Close to patient</li>
            <li>Approx. 20-30 meters by 20-30 meters</li>
            <li>Flat (less than 10° slope</li>
            <li>No debris on ground</li>
            <li>If dusty, consider wetting ground</li>
            <li>No overhead wires</li>
            <li>Do not approach aircraft until directed by flight crew</li>
        </ul>
        <p><strong>Outlined below is their protocol for injuries that may require helicopter transport:</strong></p>
        <ul>
            <li>Unable to transport by land or sea</li>
            <li>GCS ≤ 13</li>
            <li>Patient was unconscious but not yet returned to GCS 15</li>
            <li>Open or depressed skull fracture</li>
            <li>Respiratory rate ≤ 10 or ≥30</li>
            <li>BP less than 90 and or clinical signs of shock</li>
            <li>Penetrating injury to chest, neck, head, abdomen, groin or proximal extremity (above knee or elbow)</li>
            <li>2 or more proximal long bone fractures</li>
            <li>Flail chest</li>
            <li>Major amputation of extremity</li>
            <li>Burns- 2<sup>nd</sup> degree &gt; 20% or 3<sup>rd</sup> degree &gt; 10%</li>
            <li>Facial or airway burns with or without inhalation injury</li>
            <li>3<sup>rd</sup> decree burns involving eyes, neck, hands, feet or groin</li>
            <li>High voltage electrical burns</li>
            <li>Spinal cord injury with deficits</li>
            <li>Suspected pelvic fracture</li>
        </ul>
        <h3><strong>Preferred Destinations</strong></h3>
        <p><strong>Greater Vancouver Regional Trauma Centers Adult Patients:</strong></p>
        <ul>
            <li>VGH (major trauma including neurosurgical)</li>
            <li>RCH (major trauma including neurosurgical)</li>
            <li>LGH (major trauma including neurosurgical)</li>
        </ul>
        <p><strong>Greater Vancouver Pediatric Patients (Less than 17 years old)</strong></p>
        <ul>
            <li>BCCH (major trauma including neurosurgical)</li>
            <li>RCH (major trauma including neurosurgical)</li>
            <li>LGH (major trauma including neurosurgical)</li>
        </ul>
        <p><strong>Southern Vancouver Island</strong></p>
        <p>Victoria General Hospital is the preferred destination for all trauma, pediatric and maternity patients if there is not an unnecessary delay in transport. The Royal Jubilee Hospital is the preferred destination for all unstable cardiac patients requiring angioplasty. Otherwise, consider the closest emergency ward:</p>
        <ul>
            <li>Cowichan District Hospital</li>
            <li>Nanaimo Regional General Hospital</li>
            <li>North Island Hospital Campbell River and District</li>
            <li>Port McNeil and District Hospital</li>
            <li>Port Hardy Hospital</li>
        </ul>
        `),
    },
]