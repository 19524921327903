import React from 'react'
import { AppBar, BottomNavigation, BottomNavigationAction, IconButton, Toolbar } from "@material-ui/core"
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import HomeIcon from '@material-ui/icons/Home'
import { useHistory, useLocation, Link } from 'react-router-dom'
import { SECTIONS } from '../data/sections'
import canadaFlag from '../images/canada-flag.svg'

export const NavBar: React.FC = () => {
    const history = useHistory()
    const location = useLocation()
    
    const isOnHome = location.pathname === '/'

    return (
        <AppBar position="sticky" color={'secondary'} elevation={0}>
            <Toolbar>
            { isOnHome ? (
                <img
                style={{ maxWidth: '500px' }}
                src={canadaFlag}
                alt={'Canadian Flag Icon'}
                />
            ) : (
                <IconButton onClick={history.goBack} edge="start" color="inherit" aria-label="back">
                    <KeyboardBackspaceIcon />
                </IconButton>
            )}
            </Toolbar>
        </AppBar>
    )
}

const navigation = SECTIONS.map((section) => (
    <BottomNavigationAction
      component={Link}
      to={section.path}
      value={section.id.toLowerCase()}           
      label={section.name}
      icon={section.icon}
      key={section.path}
    />
  ))

export const BottomNav: React.FC = () => {
    const location = useLocation()
    let activePage = location.pathname.slice(1) // Drop leading slash

    if (activePage.indexOf('/') !== -1) {
        activePage = activePage.substr(0, activePage.indexOf('/'))
    }
    activePage = activePage || ''

    return (
        <BottomNavigation
            value={activePage}
            showLabels={true}
            style={{position: "fixed", bottom: 0, width: '100%'}}
        >
            <BottomNavigationAction
            component={Link}
            to="/"
            value=""
            label="Home"
            icon={<HomeIcon />}
            />
            {navigation}
        </BottomNavigation>
    )
}