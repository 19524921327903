import { Topic } from '.'

export const misc: Topic[] = [
    {
        id: 'multi-casualty-incident',
        sectionId: 'appendix',
        charts: [],
        title: 'Multi Casualty Incident and START Triage',
        lastUpdated: '6 Jan 2022',
        body: `
        <p><strong>Physical Assessment</strong></p>
        <p><strong>See START </strong>Triage Model (<strong>S</strong>imple <strong>T</strong>riage and <strong>R</strong>apid <strong>T</strong>reatment) on previous page.</p>
        <p><strong>Triage </strong>– objective is to sort based on priorities, in order to maximize survivors.</p>
        <p><strong>Triage Tags </strong>– use of coloured triage (surveyors) tape is best for first triage (tear off long strip, secure to wrist &amp; keep piece to aid counting); METTAG if carried is suitable for secondary triage</p>
        <p><strong>General Rules of Triage</strong></p>
        <ul>
            <li>Triage to take no more than 30 seconds per patient.</li>
            <li>Life over limb. Interventions limited to immediate life-saving manoeuvres.</li>
            <li>Airways may obstruct any time; if patient is unresponsive and unattended, reposition.</li>
            <li>A.R. and CPR: Consider the cost. (See AED Protocol1.1)</li>
            <li>Patients in shock tolerate transportation poorly: treat before and during transport.</li>
        </ul>
        <h2>TRIAGE CATEGORIES</h2>
        <p><strong>Red (Urgent) </strong>– critically injured with problems that will require immediate intervention (obvious signs of shock including breathing rate over 30, absent radial pulses, or inability to follow simple commands).</p>
        <p><strong>Yellow (Delayed) </strong>– require medical attention and stretcher transport but life is not at risk.</p>
        <p><strong>Green (Minor) </strong>– injured but can walk and care for them-selves.</p>
        <p><strong>White (Uninjured) </strong>– involved in incident but not injured.</p>
        <p><strong>Black (Deceased) </strong>– deceased or such catastrophic injuries that they will not survive to be transported.</p>
        <p><strong>Grey (Missing) </strong>– as per National SAR Manual, NOCL – for verbal report only, not tagged</p>
        `,
    },
    {
        id: 'body-recovery',
        sectionId: 'appendix',
        charts: [],
        title: 'Body Recovery',
        lastUpdated: '6 Jan 2022',
        body: `
        <p><strong>Body Substance Isolation</strong></p>
        <p>Practice standard body substance isolation (BSI) procedures, by wearing gloves, goggles, mask, disposable gown as appropriate and hand washing afterwards.</p>
        <p>Equipment should be sanitized with soap and water and disinfected (water and bleach at 10:1 or Precept) as appropriate. BSI items can be bagged and sent with the body for disposal.</p>
        <p><strong>The Body Bag</strong></p>
        <p>To place body in bag, lay bag alongside and roll to place body face up in bag, with head at top of bag so when zipper is opened face is seen first. Liner bag can be placed inside heavier mortuary pouch for transport. If bagging in water it is good practice to cut small holes in corner of liner to allow drainage – but monitor for escaping evidence.</p>
        <p><strong>Documentation</strong></p>
        <p>As noted above, follow the direction of the responsible authority. If you are the eyes of the coroner at the scene make note of findings, including position, anything unusual on the body or at the scene, or anything that happens to the body during recovery.</p>
        <ul>
            <li>Using CG camera to take digital photos or video of the body prior to recovery could be useful to investigators. These must be protected and should be placed in the custody of the CO or OIC until handed over to the investigating authority.</li>
            <li>Patient Care Record can be used to document observations, and would also be handed over.</li>
        </ul>        
        `,
    },
    {
        id: 'accidental-exposure-to-body-fluids',
        sectionId: 'appendix',
        charts: [],
        title: 'Accidental Exposure to Blood or Bodily Fluids',
        lastUpdated: '6 Jan 2022',
        body: `
        <p><strong>Assumptions</strong></p>
        <ul>
            <li>All blood or body fluids are considered to be potentially infectious.</li>
            <li>Notwithstanding the previous statement, it must be understood that the chances of contracting an infectious disease, particularly HIV, during the provision of first aid is extremely small.</li>
            <li>At highest risk are needle stick injuries, bites and blood splashes on to open wounds or mucous membranes.</li>
        </ul>
        <p><strong>Prevention</strong></p>
        <ul>
            <li>Body Substance Isolation (BSI) procedures, including; hand-washing, exam gloves and goggles.</li>
            <li>Use of disposable equipment, clean and disinfect other gear (see next page), use sharps containers, etc.</li>
        </ul>
        <p><strong>Post Exposure</strong></p>
        <ul>
            <li>Provide first aid.</li>
            <li>PUNCTURE INJURY: Seek medical advice immediately.&nbsp;</li>
            <li>Allow to bleed freely, avoid squeezing, wash with soap and water, wipe area with alcohol, and dress as for open wound.</li>
            <li>MUCOUS MEMBRANES or EYES: rinse thoroughly with water or normal saline.</li>
            <li>OPEN WOUNDS: wash well with soap or water.</li>
            <li>Identify the source: document details of the accident (type of fluid, type of injury, risk factors, etc.). Maintain confidentiality.</li>
            <li>Immediate notification to supervisor and first aid attendant, with pertinent details.</li>
            <li>If indicated access anti-retroviral therapy through local hospital emergency ward. (Do not delay in seeking treatment as most likely to be effective if started within 2 hours).</li>
            <li>Clarify with medical professionals and advise supervisor of need for any continued therapies, etc.</li>
            <li>Ensure appropriate documentation is completed and forwarded.</li>
        </ul>
        `,
    },
    {
        id: 'infection-control',
        sectionId: 'appendix',
        charts: [],
        title: 'Infection Control – Cleaning of Equipment',
        lastUpdated: '6 Jan 2022',
        body: `
        <p><strong>Sanitizing</strong></p>
        <p>The removal of gross contaminants and visible material using soap and water. Sanitizing is suitable for routine cleaning of equipment and is required prior to disinfecting items contaminated with blood or body fluids.</p>
        <p><strong>Disinfecting</strong></p>
        <p>Required when non-disposable items are contaminated with blood or body fluids. Disinfect with hospital grade disinfectant (eg. T36) or a bleach solution (10 parts water and 1 part1-part house hold bleach).</p>
        <ul>
            <li>If mixing bleach, this solution should be made up fresh.</li>
            <li>Smaller items should be soaked in the solution for 10 – 15 minutes.</li>
            <li>Larger items or areas should be should be sprayed or wiped down with solution.</li>
            <li>If using bleach rinse with fresh water after disinfecting then dry prior to storage.</li>
            <li>Wear appropriate PPE (gloves, goggles, etc.)</li>
        </ul>
        <p><strong>Sterilizing </strong>– kills all organisms with chemical means or with super-heated steam (autoclave) and would normally be done in a hospital setting.</p>
        <p><strong>Cleaning of Fluids from Floatation Garments</strong></p>
        <p>The manufacturers of floatation garments advise that bleach will degrade the fabrics and should not be used. The BC Centre for Disease Control confirmed that a hot soapy wash is sufficient for cleaning of garments and fabrics and is what is commonly used in hospitals.</p>
        `,
    },
]