import { Container } from '@material-ui/core'
import ScopedCssBaseline from '@material-ui/core/ScopedCssBaseline'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import React, { useState } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import { Home } from './components/home'
import { InstallPrompt } from './components/install'
import { BottomNav, NavBar } from './components/nav-bar'
import { Section } from './components/section'
import { SignInPrompt } from './components/sign-in'
import { Topic } from './components/topic'
import { ScrollToTop } from './components/util'
import { SECTIONS } from './data/sections'
import { ServiceWorker } from './components/service-worker'


const style = {
  minHeight: `100vh`,
  paddingBottom: `100px`,
  paddingTop: `20px`,
}

const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: `#F8333C`,
    },
    secondary: {
      main: `#000000`,
    },
    background: {
      default: '#000000',
      paper: '#222222'
    },
    error: {
      main: `#F8333C`,
    },
    success: {
      main: `#6BC4EA`,
    }
  },
  typography: {
    h1: {
      fontFamily: `'Exo 2', sans-serif`,
      textTransform: 'uppercase',
      fontSize: '4rem',
      '@media (max-width:800px)': {
        fontSize: '2.5rem',
      },
    },
    h2: {
      fontFamily: `'Exo 2', sans-serif`,
      textTransform: 'uppercase',
    },
    h3: {
      fontFamily: `'Exo 2', sans-serif`,
      textTransform: 'uppercase',
    },
    h4: {
      fontFamily: `'Exo 2', sans-serif`,
      textTransform: 'uppercase',
    },
    h5: {
      fontFamily: `'Exo 2', sans-serif`,
      textTransform: 'uppercase',
      fontSize: '2rem',
    },
    h6: {
      fontFamily: `'Exo 2', sans-serif`,
      textTransform: 'uppercase',
      fontSize: '1.5rem',
    },
  },
  shape: {
    // borderRadius: 10,
  },
  spacing: 10,
})

const sectionRoutes = SECTIONS.map((section) => (
  <Route exact path={section.path} key={section.path}>
    <Section section={section}/>
  </Route>
))

const topicRoutes = SECTIONS.map((section) => (
  <Route
    exact
    path={`${section.path}/:topicId`}
    key={section.path}
    render={(props) => <Topic {...props} sectionId={section.id} />}
  />
))

const USER_SETTINGS_KEY = 'rs.v1.user'
const storeSignedInCredentials = () => {
  window.localStorage.setItem(USER_SETTINGS_KEY, JSON.stringify({ isSignedIn: true }))
}

const isUserSignedIn = (): boolean => {
  const rawResult = window.localStorage.getItem(USER_SETTINGS_KEY)
  if (!rawResult) {
    return false
  }
  const parsedSettings = JSON.parse(rawResult)
  return parsedSettings.isSignedIn === true
}

const App: React.FC<{}> = () => {
  const [ isSignedIn, setIsSignedIn ] = useState(isUserSignedIn())
  const onSignIn = () => {
    storeSignedInCredentials()
    setIsSignedIn(true)
  }

  if (!isSignedIn) {
    return (
      <ThemeProvider theme={theme}>
        <ScopedCssBaseline>
          <SignInPrompt onSignIn={onSignIn}/>
        </ScopedCssBaseline>
      </ThemeProvider>
    )
  }
  return (
    <ThemeProvider theme={theme}>
      <ScopedCssBaseline>
        <ServiceWorker />
        <InstallPrompt />
        <Router>
          <NavBar />
          <Container style={style}>
            <ScrollToTop />
            <Switch>
              {sectionRoutes}
              {topicRoutes}
              <Route path="/">
                  <Home />
              </Route>
            </Switch>
          </Container>

          <BottomNav/>
        </Router>
      </ScopedCssBaseline>
    </ThemeProvider>
  );
}

export default App