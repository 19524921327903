import { Snackbar } from '@material-ui/core'
import React, { useEffect, useState } from 'react'

import { register } from '../serviceWorkerRegistration'

export const ServiceWorker: React.FC = () => {
    const [showReload, setShowReload] = useState(false)
    
    const onUpdate = () => {
      setShowReload(true)
    }
    
    useEffect(() => {
      register({ onUpdate: onUpdate })
    }, []);

    return (
        <Snackbar
          autoHideDuration={5000}
          open={showReload}
          onClose={() => setShowReload(false)}
          message="A new version of this manual is available. Reload the page to update."
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        />
      )
}