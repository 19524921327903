import React from 'react'
import { ReactComponent as OA1 } from './resources/3.1.1.svg'
import { ReactComponent as RespDistress } from './resources/3.2.1.svg'
import { ReactComponent as Analphylaxis } from './resources/3.3.1.svg'
import { ReactComponent as O2Therapy } from './resources/3.4.1.svg'
import { Topic } from '.'
import { generateTable, generateRow } from '../util'

export const respitory: Topic[] = [
    {
        id: 'obstructed-airway',
        sectionId: 'assessment',
        charts: [
        ],
        title: 'Obstructed Airway',
        lastUpdated: '6 Jan 2022',
        body: '',
        markup: <>
        <OA1 />
        <h2><strong>Indications</strong></h2>
        <ul>
            <li>A patient without a patent airway, whose history, suggests a foreign body airway obstruction.</li>
            <li>Patient may be conscious or unconscious.</li>
        </ul>
        <h2><strong>Contraindications</strong></h2>
        <p>None</p>
        <h2><strong>Critical History</strong></h2>
        <ul>
            <li>When did this happen?</li>
            <li>What is the foreign body?</li>
            <li>If unconscious – for how long?</li>
            <li>Was any treatment given to the patient?</li>
            <li>Is there any patient history available?</li>
        </ul>
        <h2><strong>Key Features</strong></h2>
        <ul>
            <li>Conscious – patient position will make choking obvious (ie. hands to neck)</li>
            <li>Appearance of panic.</li>
            <li>Patient may be cyanotic.</li>
        </ul>
        <h2><strong>Physical Assessment</strong></h2>
        <ul>
            <li>If mild obstruction (patient can cough), stand by and encourage to cough.</li>
            <li>If obstruction becomes severe begin back blows/abdominal thrust until object is cleared, or patient becomes unconscious</li>
            <li>Consider relieved when; conscious casualty can speak or cry; unconscious casualty can be ventilated and is not hypoxic. Transport to medical attention to ensure no damage to patients airway.</li>
            <li>If unconscious – look for foreign body prior to ventilating – if nothing seen then continue with treatment, looking in airway prior to each set of ventilations.</li>
            <li>Continue with normal patient assessment and treatments as required.</li>
        </ul>
        <h2><strong>Considerations</strong></h2>
        <ul>
            <li>If patient pregnant – no abdominal thrusts. Do 5 back blows then put patients back to a wall and to do 5 chest compressions.</li>
            <li>If patient too large to encircle for abdominal compressions then do 5 back blows and put patients back to wall and do 5 chest compressions</li>
        </ul>
        </>
    },
    {
        id: 'respiratory-distress',
        sectionId: 'assessment',
        charts: [
        ],
        title: 'Respiratory Distress, Short of Breath (SOB)',
        lastUpdated: '6 Jan 2022',
        body: '',
        markup: <>
        <RespDistress />
        <h2><strong>Notes:</strong></h2>
        <ol>
            <li>The use of ships pharmacy supply of Salbutamol is a delayed protocol.</li>
            <li>Do not delay transport to use inhaler (i.e. Salbutamol/Ventolin) from ships pharmacy.</li>
            <li>After administration of inhaler return patient to high flow O2 and titrate to SPO2 95%.</li>
            <li>Include auscultation as part of your reassessments to determine if patient has improved. Absent, diminished or unusually noisy chest sounds indicate a problem.</li>
            <li>Additional doses will usually be 2 -5mins apart.</li>
            <li>In advanced stages patient may go into respiratory failure.</li>
            <li>If the patient is having a mild or anaphylactic reaction and feels that their respiratory distress may also be helped by their prescribed inhaler:</li>
            <li>Administer the initial doses of anti-histamine and Epinephrine as per the Allergic Reaction protocol (2.3).</li>
            <li>Administer their inhaler based on this protocol.</li>
            <li>Continue to run both protocols together. Ensuring additional Epi doses and Ventolin (if from ships pharmacy) are requested from EPOS if required.</li>
        </ol>
        <h2><strong>Indications</strong></h2>
        <p>Shortness of breath with a history of asthma or COPD and carries own medication (Metered Dose Inhaler (MDI) – I.e. Salbutamol/Ventolin).</p>
        <p>In cases of allergic reaction where patient feels that their Ventolin may help with their respiratory distress. <strong>Only after the Allergic Reaction protocol 2.3 has been</strong>&nbsp;<strong>applied.</strong></p>
        <p><strong>Contraindications</strong></p>
        <p>Patient not prescribed Salbutamol (Ventolin).</p>
        <p><strong>Cautions for Salbutamol/Ventolin specifically</strong></p>
        <p>Patients who are SOB as a result of a CHF episode.</p>
        <h2><strong>Critical History</strong></h2>
        <ul>
            <li>SAMPLE and OPQRST to determine cause of SOB.</li>
            <li>Why did you call us today / how is this different today that caused you to call for help?</li>
            <li>When did this start and what brought it on?</li>
            <li>Do you have asthma / COPD?</li>
            <li>Do you carry an inhaler – have you used it? Did it provide any relief?</li>
            <li>How does this compare to previous episodes?</li>
            <li>Any other previous respiratory or cardiac conditions.</li>
            <li>Are you having chest pain with this SOB episode?</li>
        </ul>

        <h2><strong>Key Features</strong></h2>
        <p><strong>Asthma - </strong>Wide spread but reversible narrowing of airways, with bronchospasm, edema, and increased mucous secretion.</p>
        <p>Induced by triggers – allergens, cold air, stress, etc.</p>
        <p><strong>COPD – Chronic Obstructive Pulmonary Disease</strong></p>
        <p>(Chronic bronchitis or Emphysema).</p>
        <p>May have a hypoxic drive (patients drive to breath is predominantly on O2 levels as opposed to CO2) – if <strong>in respiratory distress or low SPO2- deliver high % O2, and monitor. </strong>Seek advice for long transports on high flow O2. As soon as you are able, decrease O2 delivery to maintain SPO2 of 95%.</p>
        <p>COPD patients are often chronically hypoxic and tolerate SPO2 levels of 92% when not SOB.</p>
        <h2><strong>Physical Assessment</strong></h2>
        <ul>
            <li>Primary – position for ease of breathing, begin high flow O2, reassure, place in position of comfort for ease of breathing (sitting fully upright).</li>
            <li>Assisted ventilation is sometimes required using BVM. Need for assisted vents based on quality, rate (e.g. &lt;10 or &gt;30), and patient’s colour, depth of respirations, degree of distress and air entry on auscultation. Bagging technique should allow for a prolonged exhalation.</li>
            <li>As much as possible manage any medical or traumatic cause for the distress.</li>
            <li>Auscultate after transport decision and take a good history.</li>
        </ul>
        <p>If you are auscultating the front of the chest, assess at 2<sup>nd</sup> intercostal space at mid clavicular line, fourth intercostal space mid auxiliary line and the 6<sup>th</sup> intercostal space mid auxiliary line. The back is preferred for sound generation and preservation of modesty. Assess above and below the clavicles, then just a few inches up from the bottom of the rib cage..</p>
        <ul>
            <li>Do not delay transport to administer Ventolin on scene.</li>
            <li>After inhaler (i.e. Salbutamol /Ventolin) administration – high flow O2 and titrate to 95% SPO2.</li>
        </ul>
        <h2><strong>Considerations</strong></h2>
        <p>Many things may present with SOB aside from asthma or COPD:</p>
        <ul>
            <li>cardiac issues such as CHF, dysrhythmias, or risk of ACS (heart attack)</li>
            <li>pulmonary embolism</li>
            <li>allergies, trauma</li>
            <li>spontaneous pneumothorax</li>
            <li>foreign body</li>
            <li>Chest infection.</li>
        </ul>
        <p>These conditions may not improve with inhalers (i.e. Salbutamol /Ventolin). Use the Key Features to ensure we are treating the proper cause.</p>
        <p><strong>Giving Ventolin to CHF patient</strong></p>
        <p>Repeated doses of inhaler may not improve the patients’ symptoms or condition. Discontinue use if this is the case.</p>
        {generateTable({
            header: generateRow('PNEUMONIA', 'CONGESTIVE HEART FAILURE (CHF)', 'PULMONARY EMBOLISM (PE)'),
            rows: [
                generateRow('May be weak, anxious, confused, desire to sit up, tachycardia, shocky (pale cool diaphoretic), cyanotic', 'May be weak, anxious, confused, desire to sit up, tachycardia, shocky (pale cool diaphoretic), cyanotic', 'May be weak, anxious, confused, desire to sit up, tachycardia, shocky (pale cool diaphoretic), cyanotic'),
                generateRow('Progressive SOB Rhonchi auscultation', 'Progressive or acute SOB, may be wheezing. Pulmonary edema (crackles on auscultation)', 'Acute onset SOB. Typically normal breath sounds and air entry.'),
                generateRow('', 'If only CHF not in conjunction with Cardiac pain then will have no pain. Can be caused by ACS and may be associated with pain.', 'Sudden onset focal chest pain. Typically non radiating'),
                generateRow('Deep productive cough – usually yellow/brown, could be streaked with blood', 'Productive cough may be pink in color. In PE – usually indicates a larger blockage', 'Productive cough may be pink in color. In PE – usually indicates a larger blockage'),
                generateRow('', 'Pain usually increases with inspiration and or expiration', 'Pain usually increases with inspiration and or expiration'),
                generateRow('', 'JVD', 'JVD'),
                generateRow('', 'Edema of dependent (lower) parts.', ''),
                generateRow('', '', 'Cardiac arrest if blockage is large enough'),
                generateRow('Fever / Chills. Patient can go into septic shock if left untreated', '', 'May have history of recent surgery, immobility or deep vein thrombosis.'),
                generateRow('Treat with O2 if in respiratory distress, SPO2<95%, or shock. Positive pressure ventilation as required.'),
            ],
        })}
        <p><strong>Acute Pulmonary Edema - </strong>Fluid in lungs impairs normal gas exchange.</p>
        <ul>
            <li>Distressed breathing,</li>
            <li>fluid chest sounds (crackles),</li>
            <li>JVD,</li>
            <li>peripheral edema,</li>
            <li>coughing,</li>
            <li>cyanosis.</li>
        </ul>
        <p><strong>Treatment </strong>- O2 if in respiratory distress, SPO2&lt;95%, or shock. Positive pressure ventilation as required.</p>
        <p><strong>Inhalation Injuries - </strong>Caused by breathing in heated or toxic gases (i.e phosgene, chlorine, hydrogen sulphide, ammonia, sulphur dioxide).</p>
        <ul>
            <li>Identify by history of exposure, presence of carbon or sooty particles around airway, burns or singed nose hairs or mucous membranes, etc.</li>
            <li>Significant respiratory compromise may not present for hours or potentially days post exposure as fluid shift worsens. This is rare.</li>
            <li>Symptoms may range from mild SOB to all of the key features for Acute Pulmonary Edema.</li>
        </ul>
        <p><strong>Treatment - </strong>O2 if in respiratory distress, SPO2&lt;95%, or shock. Positive pressure ventilation as required.</p>
        <p><strong>Hyperventilation - </strong>Occurs in anxious patients.</p>
        <ul>
            <li>Symptoms include numbness, tingling or cramping of hands and feet.</li>
            <li>Often brought on by anxiety but the RS must rule out serious cause.</li>
        </ul>
        <p><strong>Treatment </strong>- Reassure the casualty and coach to slow breathing. Do not use a paper bag or withhold oxygen</p>
        </>,
    },
    {
        id: 'anaphylaxis',
        sectionId: 'assessment',
        charts: [
        ],
        title: 'Allergic Reaction – Mild or Anaphylaxis',
        lastUpdated: '6 Jan 2022',
        body: '',
        markup: <>
        <Analphylaxis />
        <h2><strong>Notes</strong></h2>
        <ul>
            <li>Actively assist if the patient is too weak to use on own.</li>
        </ul>
        <p>For Epi-pen remove blue safety cap, press orange injector firmly against lateral thigh, hold 10 seconds, massage thigh. Dispose of injector in sharps.</p>
        <ul>
            <li>If patient does not have an EPI Pen and is presenting with signs and symptoms of anaphylaxis call EPOS for order to administer EPI and Ventolin from ships pharmacy.</li>
            <li>Continue transport and monitor closely. Epinephrine acts within minutes but additional dose may be required within 5 minutes.</li>
            <li>Doses for pediatric administration of Diphenhydramine (2-12 years old)</li>
        </ul>
        <p><strong>6-12 years old   25 mg</strong></p>
        <p><strong>2-6 years old	   12.5mg</strong></p>
        <p><strong>0-2	                   Do not administer</strong></p>
        <ul>
            <li>If the patient is having a mild or anaphylactic reaction and feels that their respiratory distress may also be helped by their prescribed inhaler:</li>
            <li>Administer the initial doses of anti-histamine and Epinephrine as per the Allergic Reaction protocol.</li>
            <li>Administer their inhaler based on the Respiratory Emergencies protocol (2.2).</li>
            <li>Continue to run both protocols together. Ensuring additional Epi doses are still requested from EPOS if required.</li>
        </ul>
        <h2><strong>Indications</strong></h2>
        <p>Patient with suspected mild allergic reaction or anaphylaxis.</p>
        <ul>
            <li> S - Signs and symptoms of either mild allergic reaction or Anaphylaxis</li>
            <li> H - History of an allergy (i.e. bee stings, shellfish, or drug exposure)</li>
            <li> E - Exposure to an allergen</li>
            <li> U - Unstable (in anaphylaxis) (only need one, may have all)</li>
            <li>Decreased LOC</li>
            <li>Hypotension (BP&lt;90mmHg)</li>
            <li>Respiratory distress</li>
        </ul>
        <h2><strong>Contraindications</strong></h2>
        <p>Epinephrine</p>
        <ul>
            <li>Patient does not have their own Epi, in this case call EPOS.</li>
        </ul>
        <p>Diphenhydramine (Benadryl or Allernix)</p>
        <ul>
            <li>allergy to diphenhydramine</li>
            <li>inability to swallow</li>
            <li>unconscious</li>
        </ul>
        <h2><strong>Critical History</strong></h2>
        <ul>
            <li>SAMPLE and High Return questions to determine if patient is having a mild allergic reaction or anaphylaxis.</li>
        </ul>
        <p>Note that certain medications can cause reactions – get a good history.</p>
        <ul>
            <li>Are you allergic to anything and have you been exposed to it?</li>
            <li>How long since the time of exposure?</li>
            <li>Do you feel you are getting worse, same or better?</li>
            <li>Do you carry an Epi Pen?</li>
            <li>Have you taken your EPI Pen or antihistamine for this episode?</li>
            <li>What has been your reaction in previous exposures?</li>
            <li>Did you have to take your EPI Pen, were you hospitalized?</li>
            <li>Did you need more than one dose of EPI during previous exposures?</li>
            <li>Are you allergic to Benadryl/Allernix?</li>
        </ul>
        <h2><strong>Key Features</strong></h2>
        {generateTable({
            header: generateRow('MILD ALLERGIC REACTION - NON LIFE THREATENING OVER REACTION TO AN ALLERGEN', 'ANAPHYLAXIS - SEVERE POTENTIALLY LIFE THREATENING ALLERGIC REACTION'),
            rows: [
                generateRow('Symptoms are mild', 'Symptoms of mild reaction plus:'),
                generateRow('Hives', 'Rapid onset'),
                generateRow('Itching', 'Respiratory distress'),
                generateRow('Runny nose', 'Wheezing'),
                generateRow('Watery eyes', 'Hypotension'),
                generateRow('Patient may self-medicate with anti- histamines', 'Swelling/edema'),
                generateRow('', 'Vomiting'),
                generateRow('', 'Diarrhea'),
                generateRow('', 'Patient may carry their own EPI Pen'),
            ],
        })}

        <h2><strong>Physical Assessment</strong></h2>
        <ul>
            <li>Primary – proper assessment of respiratory adequacy. High flow O2 or assisted ventilations may be required.</li>
        </ul>
        <p>Check pulses and skin condition.</p>
        <p>Look for signs and symptoms – i.e. hives. Expose and examine patient respectfully to determine extent.</p>
        <p>Remove any stingers that may still be imbedded. Position for ease of breathing or possible hypotension.</p>
        <ul>
            <li>If anaphylaxis or severe allergic reaction consider unstable and arrange for transport. Contact EPOS for critical interventions.</li>
            <li>Chest auscultation.</li>
        </ul>
        <h2><strong>Considerations</strong></h2>
        <p>Patients with a severe allergic reaction/anaphylaxis have the potential to have enough edema in the airway that airway management may become impossible without early intubation and treatment at a higher level of care.</p>
        <p>Quick assessment, treatment and transport without jeopardizing patient care is of the utmost importance. Time matters.</p>

        </>
    },
    {
        id: 'o2-therapy',
        sectionId: 'assessment',
        charts: [
            <O2Therapy />,
        ],   
        title: 'O2 Therapy',
        lastUpdated: '6 Jan 2022',
        body: '',
        markup: <>
            <h2><strong>Notes</strong></h2>
            <p>If the patient has a decreased Level of Consciousness (LOC) and reduced respiratory effort, they are in respiratory failure and need to have their respirations assisted with a BVM.</p>
            <h2><strong>Indications</strong></h2>
            <p>Oxygen is indicated in all cases where a patient shows signs of:</p>
            <ul>
                <li>Respiratory distress</li>
                <li>Patient presents with obvious signs of shock (pale, cool, clammy skin)</li>
                <li>SPO2 &lt;95%</li>
                <li>Hypoxia or suspected hypoxia</li>
                <li>CO poisoning</li>
                <li>Dive injuries</li>
                <li>Inhalation injury</li>
            </ul>
            <h2><strong>Contraindications</strong></h2>
            <p>No contraindications with the exception of safety precautions.</p>
            <h2><strong>Physical Assessment</strong></h2>
            <p>Once patient has responded to O2 therapy and time allows then O2 LPM flow can be dialed back or stopped to allow for SPO2 of 95%.</p>
            <p>Exceptions to this would be CO poisoning, inhalation injuries, shock and dive injuries.</p>
            <h2><strong>Considerations</strong></h2>
            <p>With the exception of CO poisoning, dive injuries, shock and inhalations injuries, O2 delivery that produces a SPO2 of &gt; 95% has <strong>not </strong>been shown to have benefit and in some cases shows poorer outcomes. (Specifically, in cases of childbirth, cardiac emergencies, COPD and stoke)</p>
            <h2><strong>3.4a Oxygen Flow Rates and Calculations</strong></h2>
            <p>Formula for Calculating oxygen duration:</p>
            <p>&nbsp;(Gauge Pressure-200psi) X cylinder constant = Duration in mins.</p>
            <p>Flow rate in LPM</p>

            {generateTable({
                title: 'Cylinder Constants and Volumes',
                header: generateRow('Cylinder Size', 'Cylinder Constant', 'Volume (Liters)'),
                rows: [
                    generateRow('D Cylinder', '0.16', '350'),
                    generateRow('Jumbo D', '0.30', '640'),
                    generateRow('E cylinder', '0.28', '625'),
                    generateRow('M cylinder', '1.56', '3000'),
                    generateRow('K cylinder', '3.14', '6900'),
                ]
            })}

            <p>Example - “Jumbo D” cylinder at 2000 psi at a 15 LPM flow rate should last 40 minutes ((2000*.30)/15).</p>
            {generateTable({
                title: 'Oxygen Flow Rate & Percentages',
                header: generateRow('Oxygen device', 'Flow rate', 'Oxygen %'),
                rows: [
                    generateRow('Nasal Cannula', '1-4 LPM', '24 - 36%'),
                    generateRow('Simple face mask (pediatric-adult)', '6-12 LPM', '40 - 60%'),
                    generateRow('Non-rebreather mask', '10-15 LPM', 'up to 90%'),
                    generateRow('Bag valve mask', '10-15 LPM', '60 %'),
                    generateRow('Bag valve mask with reservoir', '15 LPM', 'up to 100%'),
                ]
            })}
            <p>Filling &amp; Hydrostatic Testing – Oxygen cylinders no longer come with an expiry date. Oxygen supplier will conduct hydrostatic test of cylinder on an as required basis prior to filling (cylinder cannot be filled if 5 years passed last stamped hydro date on cylinder).</p>
        </>
    },
]